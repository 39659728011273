





































































































































import { ErrorInfo } from '@/openapi-types';
import { managementServicesStore } from '@/store/modules/management-services';
import { notificationsStore } from '@/store/modules/notifications';
import { systemStore } from '@/store/modules/system';

import {
  getErrorInfo,
  getTranslatedFieldErrors,
  isFieldError,
} from '@/util/helpers';
import ManagementServices from '@/components/systemSettings/ManagementServices.vue';
import MemberClasses from '@/components/systemSettings/MemberClasses.vue';
import { AxiosError } from 'axios';
import { mapActions, mapState } from 'pinia';
import { ValidationProvider } from 'vee-validate';

/**
 * View for 'system settings' tab
 */
import Vue, { VueConstructor } from 'vue';

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        serverAddressVP: InstanceType<typeof ValidationProvider>;
        managementServices: InstanceType<typeof ManagementServices>;
      };
    }
  >
).extend({
  components: {
    MemberClasses,
    ValidationProvider,
    ManagementServices,
  },
  data() {
    return {
      search: '' as string,
      showOnlyPending: false,
      saveInProgress: false,
      isEditingServerAddress: false,
      renewedServerAddress: '',
    };
  },
  computed: {
    ...mapState(systemStore, ['getSystemStatus']),
    serverAddress(): string | undefined {
      return this.getSystemStatus?.initialization_status
        ?.central_server_address;
    },
    instanceIdentifier(): string | undefined {
      return this.getSystemStatus?.initialization_status?.instance_identifier;
    },
  },
  created() {
    this.fetchSystemStatus();
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    ...mapActions(systemStore, [
      'fetchSystemStatus',
      'updateCentralServerAddress',
    ]),
    ...mapActions(managementServicesStore, [
      'fetchManagementServicesConfiguration',
    ]),
    async onServerAddressSave(serverAddress: string): Promise<void> {
      this.saveInProgress = true;
      try {
        await this.updateCentralServerAddress({
          central_server_address: serverAddress,
        });

        await this.fetchSystemStatus();
        this.$refs.managementServices.fetchManagementServicesConfiguration();

        this.showSuccess(
          this.$t('systemSettings.editCentralServerAddressSuccess'),
        );
        this.saveInProgress = false;
        this.isEditingServerAddress = false;
      } catch (updateError: unknown) {
        const errorInfo: ErrorInfo = getErrorInfo(updateError as AxiosError);
        if (isFieldError(errorInfo)) {
          // backend validation error
          let fieldErrors = errorInfo.error?.validation_errors;
          if (fieldErrors && this.$refs?.serverAddressVP) {
            this.$refs.serverAddressVP.setErrors(
              getTranslatedFieldErrors(
                'serverAddressUpdateBody.centralServerAddress',
                fieldErrors,
              ),
            );
          }
          this.isEditingServerAddress = true;
        } else {
          this.showError(updateError);
          this.isEditingServerAddress = false;
        }
        return;
      }
    },
    onServerAddressEdit(): void {
      this.renewedServerAddress = this.serverAddress ? this.serverAddress : '';
      this.isEditingServerAddress = true;
    },
    onCancelAddressEdit(): void {
      this.isEditingServerAddress = false;
    },
  },
});
