
















































import Vue, { PropType } from 'vue';
import {
  ManagementRequestDetailedView,
  ManagementRequestType,
} from '@/openapi-types';
import DataLine from './DetailsLine.vue';
import DataBlock from './DetailsBlock.vue';

export default Vue.extend({
  components: { DataBlock, DataLine },
  props: {
    managementRequest: {
      type: Object as PropType<ManagementRequestDetailedView>,
      required: true,
    },
  },
  computed: {
    isOwnerChange(): boolean {
      return (
        this.managementRequest.type ===
        ManagementRequestType.OWNER_CHANGE_REQUEST
      );
    },
    clientInfoTitle(): string {
      if (this.isOwnerChange) {
        return 'managementRequestDetails.ownerChangeInformation';
      }
      return 'managementRequestDetails.clientInformation';
    },
  },
});
