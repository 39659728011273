













































import Vue from 'vue';
import PageNavigation, {
  PageNavigationTab,
} from '@/components/layout/PageNavigation.vue';
import { Colors, Permissions, RouteName } from '@/global';
import { mapStores } from 'pinia';
import { useCertificationServiceStore } from '@/store/modules/trust-services';
import DetailsView from '@/components/ui/DetailsView.vue';

/**
 * Wrapper component for a certification service view
 */
export default Vue.extend({
  name: 'CertificationService',
  components: { DetailsView, PageNavigation },
  props: {
    certificationServiceId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      colors: Colors,
    };
  },
  computed: {
    ...mapStores(useCertificationServiceStore),
    certificationServiceNavigationTabs(): PageNavigationTab[] {
      return [
        {
          key: 'certification-service-details-tab-button',
          name: 'trustServices.trustService.pagenavigation.details',
          to: {
            name: RouteName.CertificationServiceDetails,
          },
          permissions: [Permissions.VIEW_APPROVED_CA_DETAILS],
        },

        {
          key: 'certification-service-settings-tab-button',
          name: 'trustServices.trustService.pagenavigation.settings',
          to: {
            name: RouteName.CertificationServiceSettings,
          },
          permissions: [Permissions.EDIT_APPROVED_CA],
        },

        {
          key: 'certification-service-ocsp-responders-tab-button',
          name: 'trustServices.trustService.pagenavigation.ocspResponders',
          to: {
            name: RouteName.CertificationServiceOcspResponders,
          },
          permissions: [Permissions.VIEW_APPROVED_CA_DETAILS],
        },

        {
          key: 'certification-service-intermediate-cas-tab-button',
          name: 'trustServices.trustService.pagenavigation.intermediateCas',
          to: {
            name: RouteName.CertificationServiceIntermediateCas,
          },
          permissions: [Permissions.VIEW_APPROVED_CA_DETAILS],
        },
      ];
    },
  },
  created() {
    this.certificationServiceStore.loadById(this.certificationServiceId);
  },
  methods: {
    navigateToCertificateDetails() {
      this.$router.push({
        name: RouteName.CertificationServiceCertificateDetails,
        params: {
          certificationServiceId: String(this.certificationServiceId),
        },
      });
    },
  },
});
