


















































import Vue from 'vue';
import { mapActions, mapState, mapStores } from 'pinia';
import { XRoadId } from '@/openapi-types';
import { clientStore } from '@/store/modules/clients';
import { memberStore } from '@/store/modules/members';
import { systemStore } from '@/store/modules/system';
import { notificationsStore } from '@/store/modules/notifications';
import { subsystemStore } from '@/store/modules/subsystems';

export default Vue.extend({
  name: 'AddMemberSubsystemDialog',
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      subsystemCode: '',
    };
  },
  computed: {
    ...mapStores(clientStore, memberStore, subsystemStore),
    ...mapState(systemStore, ['getSystemStatus']),
    formReady(): boolean {
      return !!this.subsystemCode;
    },
  },
  created() {
    this.memberStore.currentMember;
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    cancel(): void {
      this.$emit('cancel');
      this.clearForm();
    },
    clearForm(): void {
      this.subsystemCode = '';
    },
    add(): void {
      this.loading = true;
      const instanceId: string = this.getSystemStatus?.initialization_status
        ?.instance_identifier as string;
      this.subsystemStore
        .addSubsystem({
          subsystem_id: {
            member_class: this.memberStore.currentMember.client_id.member_class,
            member_code: this.memberStore.currentMember.client_id.member_code,
            subsystem_code: this.subsystemCode,
          },
        })
        .then(() => {
          this.showSuccess(
            this.$t('members.member.subsystems.subsystemSuccessfullyAdded', {
              subsystemCode: this.subsystemCode,
            }),
          );
          this.$emit('addedSubsystem');
          this.clearForm();
        })
        .catch((error) => {
          this.showError(error);
          this.$emit('cancel');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
