





































































import Vue, { VueConstructor } from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapActions, mapStores } from 'pinia';
import { useSecurityServerStore } from '@/store/modules/security-servers';
import { notificationsStore } from '@/store/modules/notifications';
import { ErrorInfo } from '@/openapi-types';
import {
  getErrorInfo,
  getTranslatedFieldErrors,
  isFieldError,
} from '@/util/helpers';
import { AxiosError } from 'axios';

/**
 * Component for a Security server details view
 */
export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        serverAddressVP: InstanceType<typeof ValidationProvider>;
      };
    }
  >
).extend({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    securityServerId: {
      type: String,
      default: '',
    },
    address: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      updatedAddress: this.address,
    };
  },
  computed: {
    ...mapStores(useSecurityServerStore),
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    open(): void {
      this.showDialog = true;
    },
    close(): void {
      this.updatedAddress = this.address;
      this.showDialog = false;
    },
    saveAddress: async function () {
      try {
        this.loading = true;
        await this.securityServerStore.updateAddress(
          this.securityServerId,
          this.updatedAddress,
        );
        this.showSuccess(
          this.$t('securityServers.dialogs.editAddress.success'),
        );
        this.close();
      } catch (updateError: unknown) {
        const errorInfo: ErrorInfo = getErrorInfo(updateError as AxiosError);
        if (isFieldError(errorInfo)) {
          // backend validation error
          let fieldErrors = errorInfo.error?.validation_errors;
          if (fieldErrors && this.$refs?.serverAddressVP) {
            this.$refs.serverAddressVP.setErrors(
              getTranslatedFieldErrors(
                'securityServerAddressDto.serverAddress',
                fieldErrors,
              ),
            );
          }
        } else {
          this.showError(updateError);
          this.close();
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
