













































































































import Vue from 'vue';
import InfoCard from '@/components/ui/InfoCard.vue';
import { mapActions, mapState, mapStores } from 'pinia';
import { useCertificationServiceStore } from '@/store/modules/trust-services';
import { Colors, Permissions, RouteName } from '@/global';
import { notificationsStore } from '@/store/modules/notifications';
import { userStore } from '@/store/modules/user';

/**
 * Component for a Certification Service details view
 */
export default Vue.extend({
  name: 'CertificationServiceDetails',
  components: {
    InfoCard,
  },
  data() {
    return {
      colors: Colors,
      showDeleteDialog: false,
      deleting: false,
    };
  },
  computed: {
    ...mapStores(useCertificationServiceStore),
    ...mapState(userStore, ['hasPermission']),
    showDelete(): boolean {
      return this.hasPermission(Permissions.DELETE_APPROVED_CA);
    },
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    confirmDelete(): void {
      if (!this.certificationServiceStore.currentCertificationService) return;
      this.deleting = true;
      this.certificationServiceStore
        .deleteById(
          this.certificationServiceStore.currentCertificationService.id,
        )
        .then(() => {
          this.showDeleteDialog = false;
          this.deleting = false;
          this.$router.replace({ name: RouteName.TrustServices });
          this.showSuccess(
            this.$t('trustServices.trustService.delete.success'),
          );
        })
        .catch((error) => {
          this.showDeleteDialog = false;
          this.deleting = false;
          this.showError(error);
        });
    },
  },
});
