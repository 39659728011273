



































/**
 * View for 'External configuration' tab
 */
import Vue from 'vue';
import Configuration from '../shared/Configuration.vue';

export default Vue.extend({
  components: {
    Configuration,
  },
  data() {
    return {};
  },

  methods: {},
});
