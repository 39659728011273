


























































































import Vue from 'vue';
import { mapActions, mapStores } from 'pinia';
import { managementRequestsStore } from '@/store/modules/managementRequestStore';
import { managementTypeToText } from '@/util/helpers';
import {
  ManagementRequestDetailedView,
  ManagementRequestType,
} from '@/openapi-types';
import MrDeclineDialog from '@/components/managementRequests/MrDeclineDialog.vue';
import MrConfirmDialog from '@/components/managementRequests/MrConfirmDialog.vue';
import MrCertificateInformation from '@/components/managementRequests/details/MrCertificateInformation.vue';
import MrClientInformation from '@/components/managementRequests/details/MrClientInformation.vue';
import MrSecurityServerInformation from '@/components/managementRequests/details/MrSecurityServerInformation.vue';
import MrInformation from '@/components/managementRequests/details/MrInformation.vue';
import { notificationsStore } from '@/store/modules/notifications';
import DetailsView from '@/components/ui/DetailsView.vue';

/**
 * Wrapper component for a certification service view
 */
export default Vue.extend({
  name: 'ManagementRequestDetails',
  components: {
    DetailsView,
    MrInformation,
    MrSecurityServerInformation,
    MrClientInformation,
    MrCertificateInformation,
    MrConfirmDialog,
    MrDeclineDialog,
  },
  props: {
    requestId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapStores(managementRequestsStore),

    managementRequest(): ManagementRequestDetailedView | null {
      return this.managementRequestsStore.currentManagementRequest;
    },
    typeText(): string {
      return managementTypeToText(
        this.managementRequestsStore.currentManagementRequest?.type,
      );
    },
    hasCertificateInfo(): boolean {
      if (!this.managementRequestsStore.currentManagementRequest) {
        return false;
      }

      return [
        ManagementRequestType.AUTH_CERT_DELETION_REQUEST,
        ManagementRequestType.AUTH_CERT_REGISTRATION_REQUEST,
      ].includes(this.managementRequestsStore.currentManagementRequest.type);
    },
    hasClientInfo(): boolean {
      if (!this.managementRequestsStore.currentManagementRequest) {
        return false;
      }

      return [
        ManagementRequestType.CLIENT_DELETION_REQUEST,
        ManagementRequestType.CLIENT_REGISTRATION_REQUEST,
        ManagementRequestType.OWNER_CHANGE_REQUEST,
      ].includes(this.managementRequestsStore.currentManagementRequest.type);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(notificationsStore, ['showError']),
    fetchData: async function () {
      this.loading = true;
      try {
        await this.managementRequestsStore.loadById(this.requestId);
      } catch (error: unknown) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
