






















































































/**
 * View for 'backup and restore' tab
 */
import Vue from 'vue';
import { mapState, mapStores } from 'pinia';
import { useConfigurationSourceStore } from '@/store/modules/configuration-sources';
import { ConfigurationPart, ConfigurationType } from '@/openapi-types';
import { Prop } from 'vue/types/options';
import { DataTableHeader } from 'vuetify';
import { userStore } from '@/store/modules/user';
import ConfigurationPartDownloadButton from './ConfigurationPartDownloadButton.vue';
import ConfigurationPartUploadButton from './ConfigurationPartUploadButton.vue';

export default Vue.extend({
  components: {
    ConfigurationPartUploadButton,
    ConfigurationPartDownloadButton,
  },
  props: {
    configurationType: {
      type: String as Prop<ConfigurationType>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      search: '' as string,
    };
  },
  computed: {
    ...mapStores(useConfigurationSourceStore),
    ...mapState(userStore, ['hasPermission']),
    configurationParts(): ConfigurationPart[] {
      return this.configurationSourceStore.getConfigurationParts(
        this.configurationType,
      );
    },

    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('globalConf.cfgParts.file') as string,
          value: 'file_name',
          class: 'xrd-table-header ts-table-header-server-code text-uppercase',
        },
        {
          text: this.$t('globalConf.cfgParts.contentIdentifier') as string,
          value: 'content_identifier',
          class: 'xrd-table-header ts-table-header-valid-from text-uppercase',
        },
        {
          text: this.$t('globalConf.cfgParts.version') as string,
          value: 'version',
          class: 'xrd-table-header ts-table-header-valid-to text-uppercase',
        },
        {
          text: this.$t('globalConf.cfgParts.updated') as string,
          value: 'file_updated_at',
          class: 'xrd-table-header ts-table-header-valid-to text-uppercase',
        },
        {
          text: '',
          value: 'actions',
          class: 'xrd-table-header ts-table-header-valid-to text-uppercase',
          align: 'end',
        },
      ];
    },
  },
  created() {
    this.fetchConfigurationParts();
  },
  methods: {
    fetchConfigurationParts() {
      this.loading = true;
      this.configurationSourceStore
        .fetchConfigurationParts(this.configurationType)
        .finally(() => (this.loading = false));
    },
  },
});
