










































import Vue from 'vue';
import { Prop } from 'vue/types/options';
import { mapState } from 'pinia';
import { userStore } from '@/store/modules/user';
import { Tab } from '@/ui-types';

export interface PageNavigationTab extends Tab {
  showAttention?: boolean;
}

export default Vue.extend({
  name: 'PageNavigation',
  props: {
    tabs: {
      type: Array as Prop<PageNavigationTab[]>,
      required: true,
    },
  },
  computed: {
    ...mapState(userStore, ['getAllowedTabs']),
    allowedTabs(): PageNavigationTab[] {
      return this.getAllowedTabs(this.tabs);
    },
  },
});
