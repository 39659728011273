














































































import Vue from 'vue';
import { FileUploadResult } from '@niis/shared-ui';
import { timestampingServicesStore } from '@/store/modules/trust-services';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapActions, mapStores } from 'pinia';
import { notificationsStore } from '@/store/modules/notifications';

export default Vue.extend({
  name: 'AddTimestampingServiceDialog',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      showCASettingsDialog: false,
      certFile: null as File | null,
      certFileTitle: '',
      tasUrl: '',
      loading: false,
    };
  },
  computed: {
    ...mapStores(timestampingServicesStore),
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    onFileUploaded(result: FileUploadResult): void {
      this.certFile = result.file;
      this.certFileTitle = result.file.name;
    },
    save(): void {
      if (!this.certFile) return;

      this.loading = true;
      this.timestampingServicesStore
        .addTimestampingService(this.tasUrl, this.certFile)
        .then(() => {
          this.showSuccess(
            this.$t('trustServices.timestampingService.dialog.add.success'),
          );
          this.$emit('save');
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => (this.loading = false));
    },
    cancel(): void {
      this.$emit('cancel');
    },
  },
});
