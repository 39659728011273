


































import Vue from 'vue';
import GlobalGroupDetails from '@/components/globalGroups/GlobalGroupDetails.vue';
import GlobalGroupMembers from '@/components/globalGroups/GlobalGroupMembers.vue';
import DetailsView from '@/components/ui/DetailsView.vue';

/**
 * Global group view
 */
export default Vue.extend({
  components: {
    DetailsView,
    GlobalGroupMembers,
    GlobalGroupDetails,
  },
  props: {
    groupCode: {
      type: String,
      required: true,
    },
  },
});
