
































































import Vue from 'vue';
import { formatDateTime, formatDateTimeSeconds } from '@/filters';
import { mapState } from 'pinia';
import { useAlerts } from '@/store/modules/alerts';
import { userStore } from '@/store/modules/user';
import { systemStore } from '@/store/modules/system';

export default Vue.extend({
  name: 'AlertsContainer',
  computed: {
    ...mapState(useAlerts, ['alerts']),
    ...mapState(userStore, ['isAuthenticated']),
    ...mapState(systemStore, ['isServerInitialized']),
    hasAlerts(): boolean {
      return this.showRestoreInProgress || this.alerts?.length > 0;
    },
    showRestoreInProgress(): boolean {
      // Mock. Add vuex getter later.
      return false;
    },
    restoreStartTime(): boolean {
      // Mock. Add vuex getter later.
      return true;
    },
  },
  methods: {
    formatDateTime,
    reformatDates(metadata: string[]): string[] {
      return metadata.map((item) => {
        if (isNaN(Date.parse(item))) {
          return item;
        }
        return formatDateTimeSeconds(item);
      });
    },
  },
});
