
































































































































import Vue from 'vue';
import { Client, PagedClients } from '@/openapi-types';
import { mapActions, mapStores } from 'pinia';
import { clientStore } from '@/store/modules/clients';
import { notificationsStore } from '@/store/modules/notifications';
import { DataOptions, DataTableHeader } from 'vuetify';
import { useGlobalGroupsStore } from '@/store/modules/global-groups';
import { debounce, toIdentifier } from '@/util/helpers';

// To provide the Vue instance to debounce
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let that: any;

export default Vue.extend({
  props: {
    groupCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      opened: false,
      loading: false,
      adding: false,
      pagingSortingOptions: {} as DataOptions,
      clients: {} as PagedClients,
      search: '',
      selectedClients: [] as Client[],
    };
  },
  computed: {
    ...mapStores(clientStore),
    ...mapStores(useGlobalGroupsStore),
    anyClientsSelected(): boolean {
      return !this.selectedClients || this.selectedClients.length === 0;
    },
    totalItems(): number {
      return this.clients.paging_metadata?.total_items || 0;
    },
    selectableClients(): Client[] {
      return this.clients.clients || [];
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('systemSettings.selectSubsystem.name') as string,
          align: 'start',
          value: 'member_name',
          class: 'xrd-table-header text-uppercase',
          sortable: false,
        },
        {
          text: this.$t('systemSettings.selectSubsystem.memberCode') as string,
          align: 'start',
          value: 'client_id.member_code',
          class: 'xrd-table-header text-uppercase',
          sortable: false,
        },
        {
          text: this.$t('systemSettings.selectSubsystem.memberClass') as string,
          align: 'start',
          value: 'client_id.member_class',
          class: 'xrd-table-header text-uppercase',
          sortable: false,
        },
        {
          text: this.$t(
            'systemSettings.selectSubsystem.subsystemCode',
          ) as string,
          align: 'start',
          value: 'client_id.subsystem_code',
          class: 'xrd-table-header text-uppercase',
          sortable: false,
        },
        {
          text: this.$t(
            'systemSettings.selectSubsystem.xroadInstance',
          ) as string,
          align: 'start',
          value: 'client_id.instance_id',
          class: 'xrd-table-header text-uppercase',
          sortable: false,
        },
        {
          text: this.$t('systemSettings.selectSubsystem.type') as string,
          align: 'start',
          value: 'client_id.type',
          class: 'xrd-table-header text-uppercase',
          sortable: false,
        },
      ];
    },
  },
  watch: {
    search: {
      handler() {
        this.pagingSortingOptions.page = 1;
        this.debouncedFetchItems();
      },
      deep: true,
    },
  },
  created() {
    that = this;
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    debouncedFetchItems: debounce(() => {
      // Debounce is used to reduce unnecessary api calls
      that.fetchClients();
    }, 600),
    async fetchClients() {
      this.loading = true;
      return this.clientStore
        .getByExcludingGroup(
          this.groupCode,
          this.search,
          this.pagingSortingOptions,
        )
        .then((resp) => {
          this.clients = resp;
        })
        .catch((error) => this.showError(error))
        .finally(() => (this.loading = false));
    },
    open() {
      this.opened = true;
    },
    changeOptions() {
      this.fetchClients();
    },
    cancel(): void {
      if (this.adding) {
        return;
      }
      this.$emit('cancel');
      this.clearForm();
      this.opened = false;
    },
    addMembers(): void {
      this.adding = true;
      const clientIds = this.selectedClients.map((client) =>
        toIdentifier(client.client_id),
      );
      this.globalGroupStore
        .addGroupMembers(this.groupCode, clientIds)
        .then((resp) => this.$emit('added', resp.data.items))
        .then(() => (this.opened = false))
        .then(() => this.showSuccessMessage(clientIds))
        .then(() => this.clearForm())
        .catch((error) => this.showError(error))
        .finally(() => (this.adding = false));
    },
    showSuccessMessage(identifiers: string[]) {
      this.showSuccess(
        this.$t('globalGroup.dialog.addMembers.success', {
          identifiers: identifiers.join(', '),
        }),
      );
    },
    clearForm(): void {
      this.selectedClients = [];
      this.pagingSortingOptions.page = 1;
      this.search = '';
    },
  },
});
