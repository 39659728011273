var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dialog)?_c('v-dialog',{attrs:{"value":_vm.dialog,"width":"824","scrollable":"","persistent":""}},[_c('v-card',{staticClass:"xrd-card"},[_c('v-card-title',[_vm._t("title",function(){return [_c('span',{staticClass:"dialog-title-text"},[_vm._v(_vm._s(_vm.$t('systemSettings.selectSecurityServer.title')))])]}),_c('v-spacer'),_c('xrd-close-button',{attrs:{"id":"dlg-close-x"},on:{"click":function($event){return _vm.cancel()}}})],2),_c('v-card-text',{staticClass:"elevation-0",staticStyle:{"height":"500px"}},[_c('v-text-field',{staticClass:"search-input",attrs:{"label":_vm.$t('systemSettings.selectSecurityServer.search'),"single-line":"","hide-details":"","autofocus":"","append-icon":"icon-Search","data-test":"management-security-server-search-field"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-0 data-table",attrs:{"item-key":"server_id.encoded_id","show-select":"","single-select":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.selectableSecurityServers,"server-items-length":_vm.securityServerStore.securityServerPagingOptions.total_items,"options":_vm.pagingSortingOptions,"no-data-text":_vm.emptyListReasoning,"loader-height":2,"footer-props":{ itemsPerPageOptions: [10, 25] }},on:{"update:options":[function($event){_vm.pagingSortingOptions=$event},_vm.changeOptions]},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"data-test":"management-security-server-checkbox","ripple":false,"value":isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"item.member_name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.member_name))])]}},{key:"item.server_id.member_code",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.server_id.member_code))])]}},{key:"item.server_id.member_class",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.server_id.member_class))])]}},{key:"item.server_id.subsystem_code",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.server_id.subsystem_code))])]}},{key:"item.server_id.instance_id",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.server_id.instance_id))])]}},{key:"item.server_id.type",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.server_id.type))])]}}],null,true),model:{value:(_vm.selectedSecurityServers),callback:function ($$v) {_vm.selectedSecurityServers=$$v},expression:"selectedSecurityServers"}})],1),_c('v-card-actions',{staticClass:"xrd-card-actions"},[_c('v-spacer'),_c('xrd-button',{staticClass:"button-margin",attrs:{"outlined":"","data-test":"cancel-button"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(_vm._s(_vm.$t('action.cancel')))]),_c('xrd-button',{attrs:{"disabled":!_vm.selectedSecurityServers || _vm.selectedSecurityServers.length === 0,"data-test":"management-security-server-select-button"},on:{"click":function($event){return _vm.selectSecurityServer()}}},[_vm._v(_vm._s(_vm.$t('action.select')))])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }