














































/** Base component for simple dialogs */

import Vue from 'vue';
import { Prop } from 'vue/types/options';

export default Vue.extend({
  name: 'GlobalGroupDeleteDialog',
  props: {
    groupCode: {
      type: String,
      required: true,
    },
    showDialog: {
      type: Boolean as Prop<boolean>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    cancelDelete(): void {
      this.$emit('cancel');
    },
    proceedWithDelete(): void {
      this.loading = true;
      this.$emit('delete');
    },
  },
});
