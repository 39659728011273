var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table-toolbar align-fix mt-0 pl-0"},[_c('div',{staticClass:"xrd-title-search align-fix mt-0 pt-0"},[_c('div',{staticClass:"xrd-view-title align-fix"},[_vm._v(" "+_vm._s(_vm.$t('tab.main.managementRequests'))+" ")]),_c('xrd-search',{staticClass:"margin-fix",attrs:{"data-test":"management-requests-search"},model:{value:(_vm.filterQuery),callback:function ($$v) {_vm.filterQuery=$$v},expression:"filterQuery"}})],1),_c('div',{staticClass:"only-pending"},[_c('v-checkbox',{staticClass:"custom-checkbox",attrs:{"label":_vm.$t('managementRequests.showOnlyPending'),"data-test":"show-only-pending-requests"},on:{"change":_vm.changeOptions},model:{value:(_vm.showOnlyPending),callback:function ($$v) {_vm.showOnlyPending=$$v},expression:"showOnlyPending"}})],1)]),_c('v-data-table',{staticClass:"elevation-0 data-table",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.managementRequestsStore.items,"search":_vm.managementRequestsStore.currentFilter.query,"must-sort":true,"items-per-page":10,"options":_vm.managementRequestsStore.pagingSortingOptions,"server-items-length":_vm.managementRequestsStore.pagingOptions.total_items,"item-key":"id","loader-height":2,"footer-props":{ itemsPerPageOptions: [10, 25, 50] },"data-test":"management-requests-table"},on:{"update:options":[function($event){return _vm.$set(_vm.managementRequestsStore, "pagingSortingOptions", $event)},_vm.changeOptions]},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('management-request-id-cell',{attrs:{"management-request":item}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.created_at)))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('mr-type-cell',{attrs:{"type":item.type}})]}},{key:"item.security_server_owner",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.security_server_owner))])]}},{key:"item.security_server_id",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.security_server_id.encoded_id))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('mr-status-cell',{attrs:{"status":item.status}})]}},{key:"item.button",fn:function(ref){
var item = ref.item;
return [_c('mr-actions-cell',{attrs:{"management-request":item},on:{"approve":_vm.changeOptions,"decline":_vm.changeOptions}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }