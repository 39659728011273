var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"form-view-wrap",attrs:{"id":"initial-configuration"}},[_c('ValidationObserver',{ref:"initializationForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('div',{staticClass:"form-content-wrap"},[_c('div',{staticClass:"form-main-title",attrs:{"data-test":"central-server-initialization-page-title"}},[_vm._v(" "+_vm._s(_vm.$t('init.initialConfiguration'))+" ")]),_c('div',{staticClass:"form-sub-title"},[_vm._v(_vm._s(_vm.$t('init.csIdentification')))]),_c('div',{staticClass:"form-row-wrap"},[_c('xrd-form-label',{attrs:{"label-text":_vm.$t('fields.init.identifier'),"help-text":_vm.$t('init.instanceIdentifier.info')}}),_c('ValidationProvider',{ref:"initializationParamsVP",attrs:{"name":"init.identifier","rules":"required","data-test":"instance-identifier--validation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"form-input",attrs:{"type":"text","label":_vm.$t('fields.init.identifier'),"error-messages":errors,"disabled":_vm.disabledFields.instanceIdentifier,"outlined":"","autofocus":"","data-test":"instance-identifier--input"},model:{value:(_vm.instanceIdentifier),callback:function ($$v) {_vm.instanceIdentifier=$$v},expression:"instanceIdentifier"}})]}}],null,true)})],1),_c('div',{staticClass:"form-row-wrap"},[_c('xrd-form-label',{attrs:{"label-text":_vm.$t('fields.init.address'),"help-text":_vm.$t('init.address.info')}}),_c('ValidationProvider',{ref:"initializationParamsVP",attrs:{"name":"init.address","rules":"required","data-test":"address--validation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"form-input",attrs:{"type":"text","label":_vm.$t('fields.init.address'),"error-messages":errors,"disabled":_vm.disabledFields.address,"outlined":"","data-test":"address--input"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}],null,true)})],1),_c('div',{staticClass:"form-sub-title"},[_vm._v(_vm._s(_vm.$t('init.softwareToken')))]),_c('div',{staticClass:"form-row-wrap"},[_c('xrd-form-label',{attrs:{"label-text":_vm.$t('fields.init.pin'),"help-text":_vm.$t('init.pin.info')}}),_c('ValidationProvider',{attrs:{"name":"init.pin","rules":"required","data-test":"pin--validation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"form-input",attrs:{"type":"password","autocomplete":"pin-code","name":"init.pin","label":_vm.$t('fields.init.pin'),"error-messages":errors,"disabled":_vm.disabledFields.pin,"outlined":"","data-test":"pin--input"},model:{value:(_vm.pin),callback:function ($$v) {_vm.pin=$$v},expression:"pin"}})]}}],null,true)})],1),_c('div',{staticClass:"form-row-wrap"},[_c('xrd-form-label',{attrs:{"label-text":_vm.$t('fields.init.confirmPin')}}),_c('ValidationProvider',{ref:"confirmPinFieldVP",attrs:{"name":"init.confirmPin","rules":"required|password:@init.pin","data-test":"confirm-pin--validation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passed = ref.passed;
return [_c('v-text-field',{staticClass:"form-input",attrs:{"type":"password","autocomplete":"pin-code","name":"init.confirmPin","label":_vm.$t('fields.init.confirmPin'),"error-messages":errors,"disabled":_vm.disabledFields.pin,"outlined":"","data-test":"confirm-pin--input"},model:{value:(_vm.pinConfirm),callback:function ($$v) {_vm.pinConfirm=$$v},expression:"pinConfirm"}},[(passed)?_c('xrd-icon-base',{attrs:{"slot":"append","color":_vm.colors.Success100,"data-test":"confirm-pin-append-input-icon"},slot:"append"},[_c('XrdIconChecked')],1):_vm._e()],1)]}}],null,true)})],1)]),_c('div',{staticClass:"button-footer"},[_c('xrd-button',{attrs:{"disabled":invalid,"loading":_vm.submitting,"data-test":"submit-button"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t('action.submit'))+" ")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }