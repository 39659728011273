








































































import { SecurityServer } from '@/openapi-types';
import { notificationsStore } from '@/store/modules/notifications';
import { useSecurityServerStore } from '@/store/modules/security-servers';
import { securityServerAuthCertStore } from '@/store/modules/security-servers-authentication-certificates';
import { mapActions, mapStores } from 'pinia';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Vue from 'vue';
export default Vue.extend({
  name: 'DeleteAuthenticationCertificateDialog',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    authenticationCertificateId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      securityServerCode: '',
      loading: false,
    };
  },
  computed: {
    ...mapStores(securityServerAuthCertStore, useSecurityServerStore),
    securityServer(): SecurityServer | null {
      return this.securityServerStore.currentSecurityServer;
    },
  },
  methods: {
    ...mapActions(notificationsStore, ['showSuccess', 'showError']),
    cancel(): void {
      this.$emit('cancel');
    },
    deleteCert(): void {
      this.loading = true;
      this.securityServerAuthCertStore
        .delete(
          this.securityServer?.server_id.encoded_id as string,
          this.authenticationCertificateId,
        )
        .then(() => {
          this.showSuccess(
            this.$t(
              'securityServers.securityServer.dialog.deleteAuthCertificate.success',
            ),
          );
          this.$emit('delete');
        })
        .catch((error) => {
          this.showError(error);
          this.$emit('cancel');
        })
        .finally(() => (this.loading = false));
    },
  },
});
