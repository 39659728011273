

































import Vue, { PropType } from 'vue';
import { ManagementRequestStatus } from '@/openapi-types';

export default Vue.extend({
  props: {
    status: {
      type: String as PropType<ManagementRequestStatus>,
      default: undefined,
    },
  },

  computed: {
    hasStatus(): boolean {
      return this.status !== undefined;
    },
    statusIconType(): string {
      if (this.status) {
        switch (this.status) {
          case ManagementRequestStatus.REVOKED:
          case ManagementRequestStatus.DECLINED:
            return 'progress-delete';
          case ManagementRequestStatus.APPROVED:
            return 'ok';
          case ManagementRequestStatus.WAITING:
          case ManagementRequestStatus.SUBMITTED_FOR_APPROVAL:
            return 'pending';
        }
      }
      return 'error';
    },
  },

  methods: {
    getStatusText(status: ManagementRequestStatus): string {
      if (status) {
        switch (status) {
          case ManagementRequestStatus.WAITING:
            return this.$t('managementRequests.pending') as string;
          case ManagementRequestStatus.APPROVED:
            return this.$t('managementRequests.approved') as string;
          case ManagementRequestStatus.DECLINED:
            return this.$t('managementRequests.rejected') as string;
          case ManagementRequestStatus.REVOKED:
            return this.$t('managementRequests.revoked') as string;
          case ManagementRequestStatus.SUBMITTED_FOR_APPROVAL:
            return this.$t('managementRequests.submitted') as string;
        }
      }
      return this.$t('managementRequests.unknown') as string;
    },
  },
});
