


















































































/**
 * Component for a Certification Service details view
 */
import Vue from 'vue';
import InfoCard from '@/components/ui/InfoCard.vue';
import { mapState, mapStores } from 'pinia';
import { useCertificationServiceStore } from '@/store/modules/trust-services';
import { Permissions } from '@/global';
import { userStore } from '@/store/modules/user';
import EditCertProfileDialog from '@/components/certificationServices/EditCertProfileDialog.vue';
import EditTlsAuthDialog from '@/components/certificationServices/EditTlsAuthDialog.vue';

export default Vue.extend({
  name: 'CertificationServiceSettings',
  components: {
    EditTlsAuthDialog,
    EditCertProfileDialog,
    InfoCard,
  },
  data() {
    return {
      showEditTlsAuthDialog: false,
      showEditCertProfileDialog: false,
    };
  },
  computed: {
    ...mapStores(useCertificationServiceStore),
    ...mapState(userStore, ['hasPermission']),
    allowEditSettings(): boolean {
      return this.hasPermission(Permissions.EDIT_APPROVED_CA);
    },
  },
  methods: {
    hideEditTlsAuthDialog() {
      this.showEditTlsAuthDialog = false;
    },
    hideEditCertProfileDialog() {
      this.showEditCertProfileDialog = false;
    },
  },
});
