









































import Vue from 'vue';
import { Permissions, RouteName } from '@/global';
import { Tab } from '@/ui-types';
import SubTabs from '@/components/layout/SubTabs.vue';
import { mapState } from 'pinia';
import { userStore } from '@/store/modules/user';

export default Vue.extend({
  components: {
    SubTabs,
  },
  computed: {
    ...mapState(userStore, ['getAllowedTabs']),
    tabs(): Tab[] {
      const allTabs: Tab[] = [
        {
          key: 'internal-conf-tab-button',
          name: 'tab.globalConf.internalConf',
          to: {
            name: RouteName.InternalConfiguration,
          },
          permissions: [Permissions.VIEW_INTERNAL_CONFIGURATION_SOURCE],
        },
        {
          key: 'external-conf-tab-button',
          name: 'tab.globalConf.externalConf',
          to: {
            name: RouteName.ExternalConfiguration,
          },
          permissions: [Permissions.VIEW_EXTERNAL_CONFIGURATION_SOURCE],
        },
        {
          key: 'trusted-anchors-tab-button',
          name: 'tab.globalConf.trustedAnchors',
          to: {
            name: RouteName.TrustedAnchors,
          },
          permissions: [Permissions.VIEW_TRUSTED_ANCHORS],
        },
      ];
      return this.getAllowedTabs(allTabs);
    },
  },
});
