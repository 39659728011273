

















































































import Vue from 'vue';

import { Colors, Permissions, RouteName } from '@/global';
import { DataOptions } from 'vuetify';
import InfoCard from '@/components/ui/InfoCard.vue';
import { useGlobalGroupsStore } from '@/store/modules/global-groups';
import { mapActions, mapState, mapStores } from 'pinia';
import { GlobalGroupResource } from '@/openapi-types';
import { notificationsStore } from '@/store/modules/notifications';
import { userStore } from '@/store/modules/user';
import GlobalGroupDeleteDialog from './GlobalGroupDeleteDialog.vue';
import GlobalGroupEditDescriptionDialog from './GlobalGroupEditDescriptionDialog.vue';

/**
 * Global group view
 */
export default Vue.extend({
  components: {
    GlobalGroupEditDescriptionDialog,
    GlobalGroupDeleteDialog,
    InfoCard,
  },
  props: {
    groupCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      colors: Colors,
      globalGroup: {} as GlobalGroupResource,
      pagingSortingOptions: {} as DataOptions,
      loading: false,
      showAddDialog: false,
      showDeleteGroupDialog: false,
      showEditDescriptionDialog: false,
    };
  },
  computed: {
    ...mapStores(useGlobalGroupsStore),
    ...mapState(userStore, ['hasPermission']),
    allowDescriptionEdit(): boolean {
      return this.hasPermission(Permissions.EDIT_GROUP_DESCRIPTION);
    },
    allowGroupDelete(): boolean {
      return this.hasPermission(Permissions.DELETE_GROUP);
    },
    allowAddAndRemoveGroupMembers(): boolean {
      return this.hasPermission(Permissions.ADD_AND_REMOVE_GROUP_MEMBERS);
    },
  },
  created() {
    this.loading = true;
    this.globalGroupStore
      .getByCode(this.groupCode)
      .then((resp) => {
        this.globalGroup = resp;
      })
      .catch((error) => {
        this.showError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    cancelDelete(): void {
      this.showDeleteGroupDialog = false;
    },
    cancelEdit(): void {
      this.showEditDescriptionDialog = false;
    },
    deleteGlobalGroup(): void {
      this.globalGroupStore
        .deleteByCode(this.groupCode)
        .then(() => {
          this.$router.replace({ name: RouteName.GlobalResources });
          this.showSuccess(this.$t('globalGroup.groupDeletedSuccessfully'));
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.showDeleteGroupDialog = false;
        });
    },
    editDescription(newDescription: string): void {
      this.globalGroupStore
        .editGroupDescription(this.groupCode, { description: newDescription })
        .then((resp) => {
          this.globalGroup = resp.data;
          this.showSuccess(this.$t('globalGroup.descriptionSaved'));
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.showEditDescriptionDialog = false;
        });
    },
  },
});
