







































































































import Vue from 'vue';
import { mapActions, mapStores } from 'pinia';
import { useOcspResponderStore } from '@/store/modules/trust-services';
import { notificationsStore } from '@/store/modules/notifications';
import { FileUploadResult } from '@niis/shared-ui';
import { OcspResponder } from '@/openapi-types';
import { RouteName } from '@/global';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default Vue.extend({
  name: 'EditOcspResponderDialog',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    ocspResponder: {
      type: Object as () => OcspResponder,
      required: true,
    },
  },
  data() {
    return {
      ocspUrl: this.ocspResponder.url,
      certFile: null as File | null,
      certFileTitle: '',
      certUploadActive: false,
      loading: false,
    };
  },
  computed: {
    ...mapStores(useOcspResponderStore),
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    cancelEdit(): void {
      this.$emit('cancel');
    },
    navigateToCertificateDetails() {
      this.$router.push({
        name: RouteName.OcspResponderCertificateDetails,
        params: {
          ocspResponderId: String(this.ocspResponder.id),
        },
      });
    },
    onFileUploaded(result: FileUploadResult): void {
      this.certFile = result.file;
      this.certFileTitle = result.file.name;
    },
    update(): void {
      this.loading = true;
      this.ocspResponderServiceStore
        .updateOcspResponder(this.ocspResponder.id, this.ocspUrl, this.certFile)
        .then(() => {
          this.showSuccess(
            this.$t('trustServices.trustService.ocspResponders.edit.success'),
          );
          this.$emit('save');
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => (this.loading = false));
    },
    cancel(): void {
      this.$emit('cancel');
    },
  },
});
