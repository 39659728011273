var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"mt-8",attrs:{"id":"intermediate-cas"}},[_c('v-data-table',{staticClass:"elevation-0 data-table",attrs:{"loading":_vm.loading,"headers":_vm.headers,"header-props":{ showHeaderBorder: false },"items":_vm.intermediateCas,"must-sort":true,"items-per-page":-1,"item-key":"id","loader-height":2,"hide-default-footer":"","data-test":"intermediate-cas-table"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',{staticClass:"borderless-table-header"},[_c('tr',[_c('th'),_c('th'),_c('th'),_c('th',{staticClass:"text-right"},[_c('div',{staticClass:"button-wrap mb-6 mt-4"},[_c('xrd-button',{attrs:{"outlined":"","data-test":"add-intermediate-ca-button"},on:{"click":function($event){_vm.showAddIntermediateCaDialog = true}}},[_c('v-icon',{staticClass:"xrd-large-button-icon"},[_vm._v("icon-Add")]),_vm._v(" "+_vm._s(_vm.$t('action.add'))+" ")],1)],1)])])])]},proxy:true},{key:"item.ca_certificate.subject_common_name",fn:function(ref){
var item = ref.item;
return [(_vm.hasPermissionToDetails)?_c('div',{staticClass:"xrd-clickable",on:{"click":function($event){return _vm.toDetails(item)}}},[_vm._v(" "+_vm._s(item.ca_certificate.subject_common_name)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.ca_certificate.subject_common_name)+" ")])]}},{key:"item.ca_certificate.not_before",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.ca_certificate.not_before)))])]}},{key:"item.ca_certificate.not_after",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.ca_certificate.not_after)))])]}},{key:"item.button",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cs-table-actions-wrap"},[_c('xrd-button',{attrs:{"text":"","outlined":false,"data-test":"view-intermediate-ca-certificate"},on:{"click":function($event){return _vm.navigateToCertificateDetails(item)}}},[_vm._v(" "+_vm._s(_vm.$t('trustServices.viewCertificate'))+" ")]),_c('xrd-button',{attrs:{"text":"","outlined":false,"data-test":"delete-intermediate-ca"},on:{"click":function($event){return _vm.openDeleteConfirmationDialog(item)}}},[_vm._v(" "+_vm._s(_vm.$t('action.delete'))+" ")])],1)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"cs-table-custom-footer"})]},proxy:true}],null,true)}),(_vm.showAddIntermediateCaDialog)?_c('AddIntermediateCaDialog',{attrs:{"ca-id":_vm.intermediateCasServiceStore.currentCs.id},on:{"cancel":_vm.hideAddIntermediateCaDialog,"save":_vm.hideAddIntermediateCaDialog}}):_vm._e(),(_vm.confirmDelete)?_c('xrd-confirm-dialog',{attrs:{"dialog":_vm.confirmDelete,"title":"trustServices.trustService.intermediateCas.delete.confirmationDialog.title","text":"trustServices.trustService.intermediateCas.delete.confirmationDialog.message","data":{
      name: _vm.selectedIntermediateCa.ca_certificate.subject_common_name,
    },"loading":_vm.deletingIntermediateCa},on:{"cancel":function($event){_vm.confirmDelete = false},"accept":_vm.deleteIntermediateCa}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }