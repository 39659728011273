


































































import Vue from 'vue';
import { Prop } from 'vue/types/options';
import TokenLogoutDialog from '@/components/tokens/TokenLogoutDialog.vue';
import TokenLoginDialog from '@/components/tokens/TokenLoginDialog.vue';
import { PossibleTokenAction, Token } from '@/openapi-types';
import { mapState } from 'pinia';
import { userStore } from '@/store/modules/user';
import { Permissions } from '@/global';

export default Vue.extend({
  components: { TokenLogoutDialog, TokenLoginDialog },
  props: {
    token: {
      type: Object as Prop<Token>,
      required: true,
    },
  },
  data() {
    return {
      showLoginDialog: false,
      showLogoutDialog: false,
    };
  },
  computed: {
    ...mapState(userStore, ['hasPermission']),
    showLogin(): boolean {
      if (!this.token.possible_actions) {
        return false;
      }
      return (
        this.hasPermission(Permissions.ACTIVATE_TOKEN) &&
        this.token.possible_actions.includes(PossibleTokenAction.LOGIN)
      );
    },
    showLogout(): boolean {
      if (!this.token.possible_actions) {
        return false;
      }
      return (
        this.hasPermission(Permissions.DEACTIVATE_TOKEN) &&
        this.token.possible_actions.includes(PossibleTokenAction.LOGOUT)
      );
    },
  },
  methods: {
    tokenLoggedIn(): void {
      this.showLoginDialog = false;
      this.$emit('token-login');
    },
    tokenLoggedOut(): void {
      this.showLogoutDialog = false;
      this.$emit('token-logout');
    },
  },
});
