


























































/**
 * View for 'backup and restore' tab
 */
import Vue from 'vue';
import ConfigurationAnchor from './ConfigurationAnchor.vue';
import ConfigurationPartsList from '@/components/configurationParts/ConfigurationPartsList.vue';
import ConfigurationDownloadUrl from './ConfigurationDownloadUrl.vue';
import { ConfigurationType } from '@/openapi-types';
import { Prop } from 'vue/types/options';
import Tokens from '@/components/tokens/Tokens.vue';

export default Vue.extend({
  components: {
    Tokens,
    ConfigurationDownloadUrl,
    ConfigurationAnchor,
    ConfigurationPartsList,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    configurationType: {
      type: String as Prop<ConfigurationType>,
      required: true,
    },
  },
  methods: {
    refreshAnchor(action: string) {
      if (action === 'add' || action === 'delete') {
        (
          this.$refs.anchor as InstanceType<typeof ConfigurationAnchor>
        ).fetchConfigurationAnchor();
      }
    },
  },
});
