



























































import Vue from 'vue';
import { mapActions, mapStores } from 'pinia';
import { useIntermediateCaStore } from '@/store/modules/trust-services';
import { notificationsStore } from '@/store/modules/notifications';
import { FileUploadResult } from '@niis/shared-ui';

export default Vue.extend({
  name: 'AddIntermediateDialog',
  props: {
    caId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      certFile: null as File | null,
      certFileTitle: '',
    };
  },
  computed: {
    ...mapStores(useIntermediateCaStore),
    formReady(): boolean {
      return !!(this.certFile && this.certFileTitle);
    },
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    cancelEdit(): void {
      this.$emit('cancel');
    },
    onFileUploaded(result: FileUploadResult): void {
      this.certFile = result.file;
      this.certFileTitle = result.file.name;
    },
    add(): void {
      this.loading = true;
      if (!this.certFile) {
        throw new Error('Certificate is null');
      }
      this.intermediateCasServiceStore
        .addIntermediateCa(this.certFile)
        .then(() => {
          this.showSuccess(
            this.$t('trustServices.trustService.intermediateCas.add.success'),
          );
          this.$emit('save');
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel(): void {
      this.$emit('cancel');
    },
  },
});
