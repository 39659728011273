










































































































import Vue from 'vue';
import { mapState } from 'pinia';
import { systemStore } from '@/store/modules/system';

export default Vue.extend({
  name: 'AppFooter',
  computed: {
    ...mapState(systemStore, { version: 'getServerVersion' }),
  },
});
