


















































/**
 * Table component for an array of keys
 */
import Vue from 'vue';
import { Prop } from 'vue/types/options';
import { ConfigurationType } from '@/openapi-types';
import { mapActions, mapState } from 'pinia';
import { tokenStore } from '@/store/modules/tokens';
import TokenExpandable from '@/components/tokens/TokenExpandable.vue';

export default Vue.extend({
  components: { TokenExpandable },
  props: {
    configurationType: {
      type: String as Prop<ConfigurationType>,
      required: true,
    },
  },
  data() {
    return {
      tokensLoading: false,
      loadingKeys: false,
    };
  },
  computed: {
    ...mapState(tokenStore, { tokens: 'getSortedTokens' }),
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(tokenStore, ['fetchTokens']),

    fetchData(): void {
      this.tokensLoading = true;
      this.fetchTokens().finally(() => (this.tokensLoading = false));
    },
    fetchKeys(action: string): void {
      this.loadingKeys = true;
      this.fetchTokens().finally(() => (this.loadingKeys = false));
      this.$emit('update-keys', action);
    },
  },
});
