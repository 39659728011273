




































































import Vue from 'vue';
import { mapActions, mapState, mapStores } from 'pinia';
import { clientStore } from '@/store/modules/clients';
import { memberStore } from '@/store/modules/members';
import { systemStore } from '@/store/modules/system';
import { notificationsStore } from '@/store/modules/notifications';
import { subsystemStore } from '@/store/modules/subsystems';
import { Client } from '@/openapi-types';
import { toIdentifier } from '@/util/helpers';

export default Vue.extend({
  name: 'DeleteMemberSubsystemDialog',
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    subsystemCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      currentMember: {} as Client,
    };
  },
  computed: {
    ...mapStores(clientStore, memberStore, subsystemStore),
    ...mapState(systemStore, ['getSystemStatus']),
  },
  created() {
    this.currentMember = this.memberStore.$state.currentMember as Client;
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    cancel(): void {
      this.$emit('cancel');
    },
    deleteSubsystem(): void {
      this.loading = true;
      this.subsystemStore
        .deleteById(
          toIdentifier(this.currentMember.client_id) + ':' + this.subsystemCode,
        )
        .then(() => {
          this.showSuccess(
            this.$t('members.member.subsystems.subsystemSuccessfullyDeleted', {
              subsystemCode: this.subsystemCode,
            }),
          );
          this.$emit('deletedSubsystem');
        })
        .catch((error) => {
          this.showError(error);
          this.$emit('cancel');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
