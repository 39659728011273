



















































import Vue from 'vue';
import { RouteName } from '@/global';
import { mapActions, mapState } from 'pinia';
import { userStore } from '@/store/modules/user';

export default Vue.extend({
  computed: {
    ...mapState(userStore, ['getUsername']),
  },
  methods: {
    ...mapActions(userStore, { storeLogout: 'logout' }),
    logout(): void {
      this.storeLogout();
      sessionStorage.clear();
      this.$router.replace({ name: RouteName.Login });
    },
  },
});
