/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * an action to change state or edit keys
 */
export enum PossibleKeyAction {
    DELETE = 'DELETE',
    ACTIVATE = 'ACTIVATE',
}