



































import Vue from 'vue';
import { mapStores } from 'pinia';
import { useOcspResponderStore } from '@/store/modules/trust-services';
import CertificateDetails from '@/components/certificate/CertificateDetails.vue';
import { CertificateDetails as CertificateDetailsType } from '@/openapi-types';

export default Vue.extend({
  name: 'OcspResponderCertificate',
  components: { CertificateDetails },
  props: {
    ocspResponderId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      certificateDetails: null as CertificateDetailsType | null,
    };
  },
  computed: {
    ...mapStores(useOcspResponderStore),
  },
  created() {
    this.ocspResponderServiceStore
      .getOcspResponderCertificate(this.ocspResponderId)
      .then((resp) => (this.certificateDetails = resp.data));
  },
});
