








































































































































































import Vue, { VueConstructor } from 'vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import i18n from '@/i18n';
import { Colors, RouteName } from '@/global';
import {
  ErrorInfo,
  InitializationStatus,
  InitialServerConf,
  TokenInitStatus,
} from '@/openapi-types';
import { swallowRedirectedNavigationError } from '@/util/helpers';
import { AxiosError } from 'axios';
import { mapActions, mapState } from 'pinia';
import { notificationsStore } from '@/store/modules/notifications';
import { systemStore } from '@/store/modules/system';

const PASSWORD_MATCH_ERROR: string = i18n.t('init.pin.pinMatchError') as string;

extend('password', {
  params: ['target'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validate(value, { target }: Record<string, any>) {
    return value === target;
  },
  message: PASSWORD_MATCH_ERROR,
});

function getTranslatedFieldErrors(
  fieldName: string,
  fieldError: Record<string, string[]>,
): string[] {
  const errors: string[] = fieldError[fieldName];
  if (errors) {
    return errors.map((errorKey: string) => {
      return i18n.t(`validationError.${errorKey}Field`).toString();
    });
  } else {
    return [];
  }
}

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        initializationForm: InstanceType<typeof ValidationObserver>;
      };
    }
  >
).extend({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {},
  data() {
    return {
      address: '',
      instanceIdentifier: '',
      pin: '',
      pinConfirm: '',
      colors: Colors,
      disabledFields: {
        address: false,
        instanceIdentifier: false,
        pin: false,
      },
      submitting: false,
    };
  },
  computed: {
    ...mapState(systemStore, ['getSystemStatus']),
  },
  created() {
    if (!this.getSystemStatus?.initialization_status) {
      // should not happen
      return;
    }

    const statusAtFirst: InitializationStatus = this.getSystemStatus
      ?.initialization_status as InitializationStatus;

    if (
      TokenInitStatus.INITIALIZED == statusAtFirst?.software_token_init_status
    ) {
      this.disabledFields.pin = true;
      this.pin = '****';
      this.pinConfirm = '****';
    }
    if (statusAtFirst?.central_server_address.length > 0) {
      this.disabledFields.address = true;
      this.address = statusAtFirst?.central_server_address;
    }
    if (statusAtFirst?.instance_identifier.length > 0) {
      this.disabledFields.instanceIdentifier = true;
      this.instanceIdentifier = statusAtFirst.instance_identifier;
    }
  },
  methods: {
    ...mapActions(notificationsStore, [
      'showError',
      'showSuccess',
      'resetNotifications',
    ]),
    ...mapActions(systemStore, ['fetchSystemStatus', 'initalizationRequest']),
    async submit() {
      // validate inputs
      const formData: InitialServerConf = {} as InitialServerConf;
      formData.instance_identifier = this.instanceIdentifier;
      formData.central_server_address = this.address;
      formData.software_token_pin = this.pin;
      this.resetNotifications();
      this.submitting = true;
      await this.initalizationRequest(formData)
        .then(() => {
          this.$router
            .push({
              name: RouteName.Members,
            })
            .catch(swallowRedirectedNavigationError);
        })
        .catch((error) => {
          const errorInfo: ErrorInfo = error.response?.data || { status: 0 };
          if (isFieldError(errorInfo)) {
            const fieldErrors = errorInfo.error?.validation_errors;
            if (fieldErrors) {
              const identifierErrors: string[] = getTranslatedFieldErrors(
                'initialServerConfDto.instanceIdentifier',
                fieldErrors,
              );
              const addressErrors: string[] = getTranslatedFieldErrors(
                'initialServerConfDto.centralServerAddress',
                fieldErrors,
              );
              this.$refs.initializationForm.setErrors({
                'init.identifier': identifierErrors,
                'init.address': addressErrors,
              });
              this.showError(error);
            }
            return;
          }

          this.showError(error);
        })
        .finally(() => {
          this.submitting = false;
          return this.fetchSystemStatus();
        });

      function isFieldError(error: ErrorInfo) {
        const errorStatus = error.status;
        return (
          400 === errorStatus && 'validation_failure' === error?.error?.code
        );
      }
    },
  },
});
