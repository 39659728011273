














































































































































import Vue from 'vue';
import AddTimestampingServiceDialog from '@/components/timestampingServices/AddTimestampingServiceDialog.vue';
import EditTimestampingServiceDialog from '@/components/timestampingServices/EditTimestampingServiceDialog.vue';
import { DataTableHeader } from 'vuetify';
import { mapActions, mapState, mapStores } from 'pinia';
import { notificationsStore } from '@/store/modules/notifications';
import { userStore } from '@/store/modules/user';
import { TimestampingService } from '@/openapi-types';
import { timestampingServicesStore } from '@/store/modules/trust-services';
import { Permissions, RouteName } from '@/global';

export default Vue.extend({
  name: 'TimestampingServicesList',
  components: {
    AddTimestampingServiceDialog,
    EditTimestampingServiceDialog,
  },
  props: {},
  data() {
    return {
      loading: false,
      confirmDelete: false,
      deletingTimestampingService: false,
      selectedTimestampingService: undefined as undefined | TimestampingService,
      showAddDialog: false,
      showEditDialog: false,
    };
  },
  computed: {
    ...mapStores(timestampingServicesStore, notificationsStore),
    ...mapState(userStore, ['hasPermission']),

    timestampingServices(): TimestampingService[] {
      return this.timestampingServicesStore.timestampingServices;
    },
    showTsaList(): boolean {
      return this.hasPermission(Permissions.VIEW_APPROVED_TSAS);
    },
    showAddTsaButton(): boolean {
      return this.hasPermission(Permissions.ADD_APPROVED_TSA);
    },
    showDeleteTsaButton(): boolean {
      return this.hasPermission(Permissions.DELETE_APPROVED_TSA);
    },
    showEditTsaButton(): boolean {
      return this.hasPermission(Permissions.EDIT_APPROVED_TSA);
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t(
            'trustServices.trustService.timestampingService.url',
          ) as string,
          align: 'start',
          value: 'url',
          class: 'xrd-table-header text-uppercase',
        },
        {
          text: this.$t(
            'trustServices.trustService.timestampingService.timestampingInterval',
          ) as string,
          align: 'start',
          value: 'timestamping_interval',
          class: 'xrd-table-header text-uppercase',
        },
        {
          text: this.$t(
            'trustServices.trustService.timestampingService.cost',
          ) as string,
          align: 'start',
          value: 'cost',
          class: 'xrd-table-header text-uppercase',
        },
        {
          text: '',
          value: 'button',
          sortable: false,
          class: 'xrd-table-header mr-table-header-buttons',
        },
      ];
    },
  },
  created() {
    this.fetchTimestampingServices();
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    fetchTimestampingServices(): void {
      this.loading = true;
      this.timestampingServicesStore
        .fetchTimestampingServices()
        .finally(() => (this.loading = false));
    },
    showDeleteDialog(item: TimestampingService): void {
      this.selectedTimestampingService = item;
      this.confirmDelete = true;
    },
    hideAddDialog(): void {
      this.showAddDialog = false;
    },
    openEditDialog(item: TimestampingService): void {
      this.selectedTimestampingService = item;
      this.showEditDialog = true;
    },
    hideEditDialog(): void {
      this.showEditDialog = false;
    },
    deleteTimestampingService(): void {
      if (!this.selectedTimestampingService) return;
      this.deletingTimestampingService = true;
      this.timestampingServicesStore
        .delete(this.selectedTimestampingService.id)
        .then(() => {
          this.showSuccess(
            this.$t(
              'trustServices.trustService.timestampingService.delete.success',
            ),
          );
          this.confirmDelete = false;
          this.deletingTimestampingService = false;
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    toMinutes(seconds: number): string {
      return '' + parseFloat((seconds / 60).toFixed(2));
    },
    navigateToCertificateDetails(item: TimestampingService) {
      this.$router.push({
        name: RouteName.TimestampingServiceCertificateDetails,
        params: {
          timestampingServiceId: String(item.id),
        },
      });
    },
  },
});
