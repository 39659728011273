







































































































































import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import { mapActions, mapState, mapStores } from 'pinia';
import { useIntermediateCaStore } from '@/store/modules/trust-services';
import { notificationsStore } from '@/store/modules/notifications';
import {
  ApprovedCertificationService,
  CertificateAuthority,
} from '@/openapi-types';
import AddIntermediateCaDialog from '@/components/intermediateCas/AddIntermediateCaDialog.vue';
import { Permissions, RouteName } from '@/global';
import { userStore } from '@/store/modules/user';

export default Vue.extend({
  name: 'IntermediateCasList',
  components: { AddIntermediateCaDialog },
  props: {
    cs: {
      type: Object as () => ApprovedCertificationService,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      showAddIntermediateCaDialog: false,
      selectedIntermediateCa: undefined as undefined | CertificateAuthority,
      confirmDelete: false,
      deletingIntermediateCa: false,
    };
  },
  computed: {
    ...mapStores(useIntermediateCaStore),
    ...mapState(userStore, ['hasPermission']),
    intermediateCas(): CertificateAuthority[] {
      return this.intermediateCasServiceStore.currentIntermediateCas;
    },
    hasPermissionToDetails(): boolean {
      return this.hasPermission(Permissions.VIEW_APPROVED_CA_DETAILS);
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t(
            'trustServices.trustService.intermediateCas.intermediateCa',
          ) as string,
          align: 'start',
          value: 'ca_certificate.subject_common_name',
          class: 'xrd-table-header text-uppercase',
        },
        {
          text: this.$t('trustServices.validFrom') as string,
          align: 'start',
          value: 'ca_certificate.not_before',
          class: 'xrd-table-header text-uppercase',
        },
        {
          text: this.$t('trustServices.validTo') as string,
          align: 'start',
          value: 'ca_certificate.not_after',
          class: 'xrd-table-header text-uppercase',
        },
        {
          text: '',
          value: 'button',
          sortable: false,
          class: 'xrd-table-header mr-table-header-buttons',
        },
      ];
    },
  },
  created() {
    this.intermediateCasServiceStore.loadByCs(this.cs);
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    toDetails(intermediateCa: CertificateAuthority) {
      this.$router.push({
        name: RouteName.IntermediateCaDetails,
        params: {
          intermediateCaId: String(intermediateCa.id),
        },
      });
    },
    hideAddIntermediateCaDialog() {
      this.showAddIntermediateCaDialog = false;
    },
    openDeleteConfirmationDialog(intermediateCa: CertificateAuthority): void {
      this.selectedIntermediateCa = intermediateCa;
      this.confirmDelete = true;
    },
    fetchIntermediateCas(): void {
      this.intermediateCasServiceStore.fetchIntermediateCas();
    },
    deleteIntermediateCa(): void {
      if (!this.selectedIntermediateCa) return;

      this.deletingIntermediateCa = true;
      this.intermediateCasServiceStore
        .deleteIntermediateCa(this.selectedIntermediateCa.id as number)
        .then(() => {
          this.showSuccess(
            this.$t(
              'trustServices.trustService.intermediateCas.delete.success',
            ),
          );
          this.confirmDelete = false;
          this.deletingIntermediateCa = false;
          this.fetchIntermediateCas();
        })
        .catch((error) => {
          this.showError(error);
        });
    },
    navigateToCertificateDetails(intermediateCa: CertificateAuthority) {
      this.$router.push({
        name: RouteName.IntermediateCACertificateDetails,
        params: {
          intermediateCaId: String(intermediateCa.id),
        },
      });
    },
  },
});
