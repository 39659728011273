




































































import Vue from 'vue';
import { Colors } from '@/global';
import { mapActions, mapState } from 'pinia';
import { notificationsStore } from '@/store/modules/notifications';
import { Notification } from '@/ui-types';

declare global {
  interface Window {
    e2eTestingMode?: boolean;
  }
}

export default Vue.extend({
  // Component for snackbar notifications
  data() {
    return {
      colors: Colors,
    };
  },
  computed: {
    ...mapState(notificationsStore, ['successNotifications']),
    // Check global window value to see if e2e testing mode should be enabled
    transitionName: () => (window.e2eTestingMode === true ? null : 'fade'),
  },
  methods: {
    ...mapActions(notificationsStore, ['deleteSuccessNotification']),
    closeSuccess(id: number): void {
      this.deleteSuccessNotification(id);
    },
    // Check global window value to see if e2e testing mode should be enabled
    snackbarTimeout(notification: Notification) {
      return window.e2eTestingMode === true ? -1 : notification.timeout;
    },
  },
});
