var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('v-card',{staticClass:"mt-8",attrs:{"flat":""}},[_c('div',{staticClass:"card-corner-button pt-4 pr-4"},[(_vm.allowMemberSubsystemAdd)?_c('xrd-button',{attrs:{"outlined":"","data-test":"add-subsystem"},on:{"click":function($event){_vm.showAddSubsystemDialog = true}}},[_c('xrd-icon-base',{staticClass:"xrd-large-button-icon"},[_c('xrd-icon-add')],1),_vm._v(" "+_vm._s(_vm.$t('members.member.subsystems.addClient'))+" ")],1):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-0 data-table",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.subsystems,"must-sort":true,"items-per-page":-1,"item-key":"id","loader-height":2,"hide-default-footer":"","data-test":"subsystems-table"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(item,index){return _c('tbody',{key:index},[(item.used_security_servers.length === 0)?_c('tr',[_c('td',{staticClass:"unregistered-subsystem"},[_vm._v(" "+_vm._s(item.subsystem_id.subsystem_code)+" ")]),_c('td',{staticClass:"unregistered-subsystem"}),_c('td',{staticClass:"unregistered-subsystem"}),_c('td',{staticClass:"status unregistered-subsystem"},[_c('xrd-icon-base',[_c('XrdIconError')],1),_vm._v(" "+_vm._s(_vm.getStatusText(undefined))+" ")],1),_c('td',{staticClass:"subsystem-actions unregistered-subsystem"},[_c('div',[(_vm.allowMemberSubsystemDelete)?_c('xrd-button',{attrs:{"text":"","outlined":false,"data-test":"delete-subsystem"},on:{"click":function($event){return _vm.deleteClicked(item)}}},[_vm._v(" "+_vm._s(_vm.$t('action.delete'))+" ")]):_vm._e()],1)])]):_vm._e(),_vm._l((item.used_security_servers),function(subitem,iSub){return _c('tr',{key:((item.subsystem_id.subsystem_code) + ":" + (subitem.server_code))},[(iSub === 0)?_c('td',{attrs:{"rowspan":item.used_security_servers.length}},[_vm._v(" "+_vm._s(item.subsystem_id.subsystem_code)+" ")]):_vm._e(),_c('td',{staticClass:"xrd-clickable"},[_vm._v(_vm._s(subitem.server_code))]),_c('td',{staticClass:"xrd-clickable"},[_vm._v(_vm._s(subitem.server_owner))]),_c('td',{staticClass:"status"},[_c('xrd-icon-base',[(subitem.status === 'APPROVED')?_c('XrdIconChecked',{attrs:{"color":_vm.colors.Success100}}):_vm._e(),(
                    subitem.status === 'WAITING' ||
                    subitem.status === 'SUBMITTED FOR APPROVAL'
                  )?_c('XrdIconInProgress',{attrs:{"color":_vm.colors.Success100}}):_vm._e(),(subitem.status === undefined)?_c('XrdIconError'):_vm._e()],1),_vm._v(" "+_vm._s(_vm.getStatusText(subitem.status))+" ")],1),_c('td',{staticClass:"subsystem-actions"},[_c('div',[(
                    subitem.status === 'APPROVED' &&
                    _vm.allowToUnregisterMemberSubsystem
                  )?_c('xrd-button',{attrs:{"text":"","outlined":false},on:{"click":function($event){return _vm.unregisterClicked(item, subitem)}}},[_vm._v(" "+_vm._s(_vm.$t('action.unregister'))+" ")]):_vm._e(),(subitem.status === 'WAITING')?_c('xrd-button',{attrs:{"text":"","outlined":false}},[_vm._v(" "+_vm._s(_vm.$t('action.approve'))+" ")]):_vm._e(),(subitem.status === 'WAITING')?_c('xrd-button',{attrs:{"text":"","outlined":false}},[_vm._v(" "+_vm._s(_vm.$t('action.decline'))+" ")]):_vm._e()],1)])])})],2)})}},{key:"footer",fn:function(){return [_c('div',{staticClass:"custom-footer"})]},proxy:true}])}),(_vm.showAddSubsystemDialog)?_c('AddMemberSubsystemDialog',{attrs:{"show-dialog":_vm.showAddSubsystemDialog,"member":_vm.memberStore.currentMember,"data-test":"add-member-to-group"},on:{"cancel":_vm.cancel,"addedSubsystem":_vm.addedSubsystem}}):_vm._e(),(_vm.showDeleteDialog)?_c('DeleteMemberSubsystemDialog',{attrs:{"show-dialog":_vm.showDeleteDialog,"subsystem-code":_vm.clickedSubsystemCode,"data-test":"delete-subsystem"},on:{"cancel":_vm.cancel,"deletedSubsystem":_vm.deletedSubsystem}}):_vm._e(),(_vm.showUnregisterDialog)?_c('UnregisterMemberSubsystemDialog',{attrs:{"show-dialog":_vm.showUnregisterDialog,"subsystem-code":_vm.clickedSubsystemCode,"server-code":_vm.clickedServerCode,"data-test":"unregister-subsystem"},on:{"cancel":_vm.cancel,"unregisteredSubsystem":_vm.unregisteredSubsystem}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }