




















































































import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import DeleteAuthenticationCertificateDialog from '@/components/securityServers/DeleteAuthenticationCertificateDialog.vue';
import { Permissions, RouteName } from '@/global';
import { userStore } from '@/store/modules/user';
import { mapState, mapStores } from 'pinia';
import { SecurityServerAuthenticationCertificateDetails } from '@/openapi-types';
import { securityServerAuthCertStore } from '@/store/modules/security-servers-authentication-certificates';

export default Vue.extend({
  components: { DeleteAuthenticationCertificateDialog },
  data() {
    return {
      loading: false,
      showDeleteConfirmationDialog: false,
      authCertIdForDeletion: undefined as number | undefined,
    };
  },
  computed: {
    ...mapStores(securityServerAuthCertStore),
    ...mapState(userStore, ['hasPermission']),
    authenticationCertificates(): SecurityServerAuthenticationCertificateDetails[] {
      return this.securityServerAuthCertStore.authenticationCertificates;
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t(
            'securityServers.securityServer.certificationAuthority',
          ) as string,
          align: 'start',
          value: 'issuer_common_name',
          class: 'xrd-table-header',
        },
        {
          text: this.$t(
            'securityServers.securityServer.serialNumber',
          ) as string,
          align: 'start',
          value: 'serial',
          class: 'xrd-table-header',
        },
        {
          text: this.$t('securityServers.securityServer.subject') as string,
          align: 'start',
          value: 'subject_distinguished_name',
          class: 'xrd-table-header',
        },
        {
          text: this.$t('securityServers.securityServer.expires') as string,
          align: 'start',
          value: 'not_after',
          class: 'xrd-table-header',
        },
        {
          text: '',
          value: 'button',
          sortable: false,
          class: 'xrd-table-header',
        },
      ];
    },
  },
  created() {
    this.fetchSecurityServerAuthenticationCertificates();
  },
  methods: {
    fetchSecurityServerAuthenticationCertificates(): void {
      this.loading = true;
      this.securityServerAuthCertStore
        .fetch(this.$route.params.serverId)
        .finally(() => (this.loading = false));
    },
    hasDeletePermission(): boolean {
      return this.hasPermission(Permissions.DELETE_SECURITY_SERVER_AUTH_CERT);
    },
    openDeleteConfirmationDialog(authCertId: number): void {
      this.authCertIdForDeletion = authCertId;
      this.showDeleteConfirmationDialog = true;
    },
    cancelDeletion(): void {
      this.showDeleteConfirmationDialog = false;
    },
    finishDeletion(): void {
      this.showDeleteConfirmationDialog = false;
      this.fetchSecurityServerAuthenticationCertificates();
    },
    navigateToCertificateDetails(authCertId: number): void {
      this.$router.push({
        name: RouteName.SecurityServerAuthenticationCertificate,
        params: {
          authenticationCertificateId: authCertId.toString(),
        },
      });
    },
  },
});
