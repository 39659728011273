








































































































import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import { mapActions, mapStores } from 'pinia';
import { notificationsStore } from '@/store/modules/notifications';
import { debounce } from '@/util/helpers';
import { managementRequestsStore } from '@/store/modules/managementRequestStore';
import { ManagementRequestStatus } from '@/openapi-types';
import ManagementRequestIdCell from '@/components/managementRequests/MrIdCell.vue';
import MrActionsCell from '@/components/managementRequests/MrActionsCell.vue';
import MrStatusCell from '@/components/managementRequests/MrStatusCell.vue';
import MrTypeCell from '@/components/managementRequests/MrTypeCell.vue';

// To provide the Vue instance to debounce
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let that: any;

/**
 * General component for Management requests
 */
export default Vue.extend({
  name: 'ManagementRequestsList',
  components: {
    MrTypeCell,
    MrStatusCell,
    MrActionsCell,
    ManagementRequestIdCell,
  },
  data() {
    return {
      loading: false, //is data being loaded
    };
  },
  computed: {
    ...mapStores(managementRequestsStore),
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('global.id') as string,
          align: 'start',
          value: 'id',
          class: 'xrd-table-header mr-table-header-id',
        },
        {
          text: this.$t('global.created') as string,
          align: 'start',
          value: 'created_at',
          class: 'xrd-table-header mr-table-header-created',
        },
        {
          text: this.$t('global.type') as string,
          align: 'start',
          value: 'type',
          class: 'xrd-table-header mr-table-header-type',
        },
        {
          text: this.$t('managementRequests.securityServerOwnerName') as string,
          align: 'start',
          value: 'security_server_owner',
          class: 'xrd-table-header mr-table-header-owner-name',
        },
        {
          text: this.$t('managementRequests.securityServerId') as string,
          align: 'start',
          value: 'security_server_id',
          class: 'xrd-table-header mr-table-header-owner-id',
        },
        {
          text: this.$t('global.status') as string,
          align: 'start',
          value: 'status',
          class: 'xrd-table-header mr-table-header-status',
        },
        {
          text: '',
          value: 'button',
          sortable: false,
          class: 'xrd-table-header mr-table-header-buttons',
        },
      ];
    },
    showOnlyPending: {
      get(): boolean {
        return (
          this.managementRequestsStore.currentFilter.status ===
          ManagementRequestStatus.WAITING
        );
      },
      set(value: boolean) {
        this.managementRequestsStore.pagingSortingOptions.page = 1;
        this.managementRequestsStore.currentFilter.status = value
          ? ManagementRequestStatus.WAITING
          : undefined;
      },
    },
    filterQuery: {
      get(): string {
        return this.managementRequestsStore.currentFilter.query || '';
      },
      set(value: string) {
        this.managementRequestsStore.pagingSortingOptions.page = 1;
        this.managementRequestsStore.currentFilter.query = value;
      },
    },
  },
  watch: {
    filterQuery: {
      handler(newValue, oldValue) {
        this.debouncedFetchItems();
      },
    },
  },
  created() {
    that = this;
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    debouncedFetchItems: debounce(() => {
      // Debounce is used to reduce unnecessary api calls
      that.fetchItems();
    }, 600),
    changeOptions: async function () {
      await this.fetchItems();
    },
    fetchItems: async function () {
      this.loading = true;

      try {
        await this.managementRequestsStore.find(
          this.managementRequestsStore.pagingSortingOptions,
          this.managementRequestsStore.currentFilter,
        );
      } catch (error: unknown) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
