























































































































































































import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import { Colors, Permissions } from '@/global';
import { mapActions, mapState, mapStores } from 'pinia';
import { userStore } from '@/store/modules/user';
import { memberStore } from '@/store/modules/members';
import { subsystemStore } from '@/store/modules/subsystems';
import { notificationsStore } from '@/store/modules/notifications';
import AddMemberSubsystemDialog from '@/views/Members/Member/Subsystems/AddMemberSubsystemDialog.vue';
import DeleteMemberSubsystemDialog from '@/views/Members/Member/Subsystems/DeleteMemberSubsystemDialog.vue';
import UnregisterMemberSubsystemDialog from '@/views/Members/Member/Subsystems/UnregisterMemberSubsystemDialog.vue';
import {
  ManagementRequestStatus,
  Subsystem,
  UsedSecurityServers,
} from '@/openapi-types';

// To provide the Vue instance to debounce
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let that: any;

/**
 * Component for Member Subsystem
 */
export default Vue.extend({
  name: 'MemberSubsystems',
  components: {
    DeleteMemberSubsystemDialog,
    AddMemberSubsystemDialog,
    UnregisterMemberSubsystemDialog,
  },
  props: {
    memberid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      colors: Colors,

      showAddSubsystemDialog: false,
      showDeleteDialog: false,
      showUnregisterDialog: false,

      loading: false,
      search: '',

      subsystems: [] as Subsystem[],

      clickedSubsystemCode: '',
      clickedServerCode: '',
    };
  },
  computed: {
    ...mapState(userStore, ['hasPermission']),
    ...mapStores(memberStore, subsystemStore),
    headers(): DataTableHeader[] {
      return [
        {
          text:
            (this.$t('members.member.subsystems.subsystemcode') as string) +
            ' (' +
            this.subsystems.length +
            ')',
          align: 'start',
          value: 'subsystem_id.subsystem_code',
          class: 'xrd-table-header subsystems-table-header-code',
        },
        {
          text: this.$t('members.member.subsystems.servercode') as string,
          align: 'start',
          value: 'usedSecurityServers[0].server_code',
          class: 'xrd-table-header subsystems-table-header-server-code',
        },
        {
          text: this.$t('members.member.subsystems.serverOwner') as string,
          align: 'start',
          value: 'usedSecurityServers[0].server_owner',
          class: 'xrd-table-header subsystems-table-header-server-owner',
        },
        {
          text: this.$t('members.member.subsystems.status') as string,
          align: 'start',
          value: 'usedSecurityServers[0].status',
          class: 'xrd-table-header subsystems-table-header-status',
        },
        {
          text: '',
          value: 'button',
          sortable: false,
          class: 'xrd-table-header subsystems-table-header-buttons',
        },
      ];
    },
    allowMemberSubsystemAdd(): boolean {
      return this.hasPermission(Permissions.ADD_MEMBER_SUBSYSTEM);
    },
    allowMemberSubsystemDelete(): boolean {
      return this.hasPermission(Permissions.REMOVE_MEMBER_SUBSYSTEM);
    },
    allowToUnregisterMemberSubsystem(): boolean {
      return this.hasPermission(Permissions.UNREGISTER_SUBSYSTEM);
    },
  },
  created() {
    that = this;

    this.loading = true;
    this.subsystemStore
      .loadByMemberId(this.memberid)
      .then((resp) => {
        this.subsystems = resp;
      })
      .catch((error) => {
        this.showError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    deleteClicked(subsystem: Subsystem) {
      this.clickedSubsystemCode = subsystem.subsystem_id
        ?.subsystem_code as string;
      this.showDeleteDialog = true;
    },
    unregisterClicked(subsystem: Subsystem, subitem: UsedSecurityServers) {
      this.clickedSubsystemCode = subsystem.subsystem_id
        ?.subsystem_code as string;
      this.clickedServerCode = subitem.server_code as string;
      this.showUnregisterDialog = true;
    },
    addedSubsystem() {
      this.showAddSubsystemDialog = false;
      this.refetchSubsystems();
    },
    deletedSubsystem() {
      this.showDeleteDialog = false;
      this.clickedSubsystemCode = '';
      this.refetchSubsystems();
    },
    unregisteredSubsystem() {
      this.showUnregisterDialog = false;
      this.clickedSubsystemCode = '';
      this.clickedServerCode = '';
      this.refetchSubsystems();
    },
    cancel() {
      this.showAddSubsystemDialog = false;
      this.showDeleteDialog = false;
      this.showUnregisterDialog = false;
      this.clickedSubsystemCode = '';
      this.clickedServerCode = '';
    },
    refetchSubsystems() {
      this.loading = true;

      this.subsystemStore
        .loadByMemberId(this.memberid)
        .then((resp) => {
          this.subsystems = resp;
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getStatusText(status: string) {
      if (status) {
        switch (status) {
          case ManagementRequestStatus.APPROVED:
            return this.$t('securityServers.registered') as string;
          case ManagementRequestStatus.WAITING:
          case ManagementRequestStatus.SUBMITTED_FOR_APPROVAL:
            return this.$t('securityServers.pending') as string;
        }
      }
      return this.$t('securityServers.unregistered') as string;
    },
  },
});
