








































import Vue from 'vue';
import { mapActions, mapStores } from 'pinia';
import { notificationsStore } from '@/store/modules/notifications';
import { useSigningKeyStore } from '@/store/modules/signing-keys';
import { ConfigurationSigningKey } from '@/openapi-types';
import { Prop } from 'vue/types/options';

export default Vue.extend({
  props: {
    signingKey: {
      type: Object as Prop<ConfigurationSigningKey>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapStores(useSigningKeyStore),
    label() {
      const key: ConfigurationSigningKey = this.signingKey;
      return { label: key?.label?.label || key.id };
    },
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    cancel(): void {
      this.$emit('cancel');
    },
    confirmDelete(): void {
      this.loading = true;
      this.signingKeyStore
        .deleteSigningKey(this.signingKey.id)
        .then(() => {
          this.showSuccess(this.$t('keys.dialog.delete.success'));
          this.$emit('key-delete');
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
