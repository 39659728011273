


















































































import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import { mapActions, mapState, mapStores } from 'pinia';
import { useGlobalGroupsStore } from '@/store/modules/global-groups';
import { notificationsStore } from '@/store/modules/notifications';
import { GlobalGroupResource } from '@/openapi-types';
import { Permissions, RouteName } from '@/global';
import AddGroupDialog from './AddGroupDialog.vue';
import { userStore } from '@/store/modules/user';

export default Vue.extend({
  name: 'GlobalResourcesList',
  components: { AddGroupDialog },
  data() {
    return {
      showAddGroupDialog: false,
    };
  },
  computed: {
    ...mapStores(useGlobalGroupsStore, notificationsStore),
    ...mapState(userStore, ['hasPermission']),
    globalGroups(): GlobalGroupResource[] {
      return this.globalGroupStore.globalGroups;
    },
    groupsLoading(): boolean {
      return this.globalGroupStore.groupsLoading;
    },
    allowAddGlobalGroup(): boolean {
      return this.hasPermission(Permissions.ADD_GLOBAL_GROUP);
    },
    globalGroupsHeaders(): DataTableHeader[] {
      return [
        {
          text: this.$t('globalResources.code') as string,
          align: 'start',
          value: 'code',
          class: 'xrd-table-header ss-table-header-sercer-code',
        },
        {
          text: this.$t('globalResources.description') as string,
          align: 'start',
          value: 'description',
          class: 'xrd-table-header ss-table-header-owner-name',
        },
        {
          text: this.$t('globalResources.memberCount') as string,
          align: 'start',
          value: 'member_count',
          class: 'xrd-table-header ss-table-header-owner-code',
        },
        {
          text: this.$t('globalResources.updated') as string,
          align: 'start',
          value: 'updated_at',
          class: 'xrd-table-header ss-table-header-owner-class',
        },
      ];
    },
  },
  created() {
    this.fetchAllGroups();
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    closeAddGroupDialog(): void {
      this.showAddGroupDialog = false;
    },
    groupAdded(): void {
      this.showAddGroupDialog = false;
    },
    toDetails(globalGroup: GlobalGroupResource): void {
      this.$router.push({
        name: RouteName.GlobalGroup,
        params: { groupCode: globalGroup.code || '' },
      });
    },
    fetchAllGroups(): void {
      this.globalGroupStore.findAll().catch((error) => {
        this.showError(error);
      });
    },
  },
});
