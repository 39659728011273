





























































import Vue from 'vue';
import { mapActions, mapStores } from 'pinia';
import { notificationsStore } from '@/store/modules/notifications';
import { useSigningKeyStore } from '@/store/modules/signing-keys';
import { ConfigurationSigningKey, ConfigurationType } from '@/openapi-types';
import { Prop } from 'vue/types/options';
import { ValidationProvider } from 'vee-validate';

export default Vue.extend({
  components: { ValidationProvider },
  props: {
    configurationType: {
      type: String as Prop<ConfigurationType>,
      required: true,
    },
    tokenId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      label: '',
    };
  },
  computed: {
    ...mapStores(useSigningKeyStore),
    isValid(): boolean {
      return this.label?.length > 0 && this.label?.length < 256;
    },
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    cancel(): void {
      this.label = '';
      this.$emit('cancel');
    },
    addKey(): void {
      this.loading = true;
      this.signingKeyStore
        .addSigningKey(this.configurationType, {
          key_label: this.label,
          token_id: this.tokenId,
        })
        .then((res) => {
          const key: ConfigurationSigningKey = res.data;
          this.showSuccess(
            this.$t('keys.dialog.add.success', {
              label: key?.label?.label || key.id,
            }),
          );
          this.$emit('key-add');
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
