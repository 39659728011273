


































import Vue from 'vue';
import { mapStores } from 'pinia';
import {
  useCertificationServiceStore,
  useIntermediateCaStore,
} from '@/store/modules/trust-services';
import OcspRespondersList from '@/components/ocspResponders/OcspRespondersList.vue';

export default Vue.extend({
  name: 'IntermediateCaOcspResponders',
  components: { OcspRespondersList },
  computed: {
    ...mapStores(useCertificationServiceStore, useIntermediateCaStore),
  },
});
