

































































import Vue from 'vue';
import { Colors } from '@/global';
import { mapState } from 'pinia';
import { systemStore } from '@/store/modules/system';

export default Vue.extend({
  name: 'Toolbar',
  data() {
    return {
      colors: Colors,
    };
  },
  computed: {
    ...mapState(systemStore, ['getSystemStatus']),
    ...mapState(systemStore, ['getSystemStatus', 'isServerInitialized']),
    initializationParameters() {
      return this.getSystemStatus?.initialization_status;
    },
    isInitialized(): boolean {
      return this.isServerInitialized;
    },
    isAuthenticated(): boolean {
      return true;
    },
    systemStatus() {
      return this.getSystemStatus;
    },
    isHighAvailabilityConfigured() {
      return this.getSystemStatus?.high_availability_status?.is_ha_configured;
    },
  },
});
