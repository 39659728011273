


























































/**
 * View for 'backup and restore' tab
 */
import Vue from 'vue';
import { mapStores } from 'pinia';
import { ConfigurationType, GlobalConfDownloadUrl } from '@/openapi-types';
import { Prop } from 'vue/types/options';
import { useConfigurationSourceStore } from '@/store/modules/configuration-sources';
import { DataTableHeader } from 'vuetify';

export default Vue.extend({
  props: {
    configurationType: {
      type: String as Prop<ConfigurationType>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapStores(useConfigurationSourceStore),
    urls(): GlobalConfDownloadUrl[] {
      return [
        this.configurationSourceStore.getDownloadUrl(this.configurationType),
      ];
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('globalConf.downloadUrl.urlAddress') as string,
          align: 'start',
          value: 'url',
          class: 'xrd-table-header text-uppercase',
        },
      ];
    },
  },
  created() {
    this.fetchDownloadUrl();
  },
  methods: {
    fetchDownloadUrl() {
      this.loading = true;
      this.configurationSourceStore
        .fetchDownloadUrl(this.configurationType)
        .finally(() => (this.loading = false));
    },
    openInNewTab(url: string) {
      window.open(url, '_blank', 'noreferrer');
    },
  },
});
