





















































































import Vue from 'vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Client } from '@/openapi-types';
import { mapActions, mapStores } from 'pinia';
import { memberStore } from '@/store/modules/members';
import { toIdentifier } from '@/util/helpers';
import { notificationsStore } from '@/store/modules/notifications';

export default Vue.extend({
  name: 'MemberDeleteDialog',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    member: {
      type: Object as () => Client,
      required: true,
    },
  },
  data() {
    return { loading: false, enteredCode: '' };
  },
  computed: {
    ...mapStores(memberStore),
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    cancelDelete(): void {
      this.enteredCode = '';
      this.$emit('cancel');
    },
    proceedDelete(): void {
      this.loading = true;
      this.memberStore
        .deleteById(toIdentifier(this.member.client_id))
        .then(() => {
          this.$emit('deleted');
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
