
































import Vue from 'vue';
import { mapStores } from 'pinia';
import { useIntermediateCaStore } from '@/store/modules/trust-services';
import CertificateDetails from '@/components/certificate/CertificateDetails.vue';
import { CertificateDetails as CertificateDetailsType } from '@/openapi-types';

export default Vue.extend({
  name: 'IntermediateCaCertificate',
  components: { CertificateDetails },
  props: {
    intermediateCaId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      certificateDetails: null as CertificateDetailsType | null,
    };
  },
  computed: {
    ...mapStores(useIntermediateCaStore),
  },
  created() {
    this.intermediateCasServiceStore
      .getIntermediateCa(this.intermediateCaId)
      .then((resp) => (this.certificateDetails = resp.data.ca_certificate));
  },
});
