









































import Vue from 'vue';
import { mapState, mapStores } from 'pinia';
import { useConfigurationSourceStore } from '@/store/modules/configuration-sources';
import { ConfigurationPart, ConfigurationType } from '@/openapi-types';
import { Prop } from 'vue/types/options';
import { userStore } from '@/store/modules/user';
import { Permissions } from '@/global';

export default Vue.extend({
  props: {
    configurationType: {
      type: String as Prop<ConfigurationType>,
      required: true,
    },
    configurationPart: {
      type: Object as Prop<ConfigurationPart>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapStores(useConfigurationSourceStore),
    ...mapState(userStore, ['hasPermission']),

    showDownloadButton(): boolean {
      return (
        this.hasPermission(Permissions.DOWNLOAD_CONFIGURATION_PART) &&
        (this.configurationPart.file_updated_at?.length || 0) > 0
      );
    },
  },
  methods: {
    download() {
      this.loading = true;
      this.configurationSourceStore
        .downloadConfigurationPartDownloadUrl(
          this.configurationType,
          this.configurationPart.content_identifier,
          this.configurationPart.version,
        )
        .finally(() => (this.loading = false));
    },
  },
});
