var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5",attrs:{"id":"global-groups"}},[_c('v-card',{attrs:{"flat":""}},[_c('div',{staticClass:"card-top"},[_c('div',{staticClass:"card-main-title"},[_vm._v(_vm._s(_vm.$t('globalConf.cfgParts.title')))])]),_c('v-card-text',{staticClass:"px-0"},[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.configurationParts,"search":_vm.search,"sort-by":['file_name'],"must-sort":true,"items-per-page":-1,"item-key":"content_identifier","loader-height":2,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.file_updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"data-test":("configuration-part-" + (item.content_identifier) + "-updated-at")}},[_vm._v(" "+_vm._s(_vm._f("formatDateTimeSeconds")(item.file_updated_at))+" ")])]}},{key:"item.content_identifier",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"data-test":("configuration-part-" + (item.content_identifier))}},[_vm._v(" "+_vm._s(item.content_identifier)+" ")])]}},{key:"item.version",fn:function(ref){
var item = ref.item;
return [(item.version === 0)?[_vm._v(" "+_vm._s(_vm.$t('globalConf.cfgParts.allVersions'))+" ")]:[_vm._v(" "+_vm._s(item.version)+" ")]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('configuration-part-download-button',{attrs:{"configuration-type":_vm.configurationType,"configuration-part":item}}),_c('configuration-part-upload-button',{attrs:{"configuration-type":_vm.configurationType,"configuration-part":item},on:{"save":_vm.fetchConfigurationParts}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"custom-footer"})]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }