

























































































import Vue from 'vue';
import { FileUploadResult } from '@niis/shared-ui';
import { Prop } from 'vue/types/options';

export default Vue.extend({
  name: 'AddCertificationServiceDialog',
  props: {
    showDialog: {
      type: Boolean as Prop<boolean>,
      required: true,
    },
  },
  data() {
    return {
      showCASettingsDialog: false,
      certFile: null as File | null,
      certFileTitle: '',
      certProfile: '',
      tlsAuthOnly: false,
      showUploadCertificateDialog: this.showDialog,
      loading: false,
    };
  },
  methods: {
    onFileUploaded(result: FileUploadResult): void {
      this.certFile = result.file;
      this.certFileTitle = result.file.name;
    },
    onUpload(): void {
      this.showCASettingsDialog = true;
      this.showUploadCertificateDialog = false;
    },
    onSave(): void {
      if (this.certFile !== null) {
        this.loading = true;
        const certService = {
          certificate: this.certFile,
          tls_auth: this.tlsAuthOnly.toString(),
          certificate_profile_info: this.certProfile,
        };
        this.$emit('save', certService, {
          done: () => {
            this.loading = false;
            this.clearForm();
          },
        });
      }
    },
    cancel(): void {
      this.$emit('cancel');
      this.clearForm();
    },
    clearForm(): void {
      this.certFile = null as File | null;
      this.certProfile = '';
      this.tlsAuthOnly = false;
      this.certFileTitle = '';
    },
  },
});
