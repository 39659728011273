





















































import Vue from 'vue';
import { mapActions, mapState } from 'pinia';
import { notificationsStore } from '@/store/modules/notifications';

export default Vue.extend({
  name: 'InitNotification',
  computed: {
    ...mapState(notificationsStore, {
      showNotification: 'continueInitialisation',
    }),
  },
  methods: {
    ...mapActions(notificationsStore, ['setContinueInit']),
  },
});
