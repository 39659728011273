






































































































import Vue from 'vue';
import { Prop } from 'vue/types/options';
import { Colors, Permissions } from '@/global';
import { mapActions, mapState } from 'pinia';
import { tokenStore } from '@/store/modules/tokens';
import { userStore } from '@/store/modules/user';
import {
  ConfigurationSigningKey,
  ConfigurationType,
  PossibleTokenAction,
  Token,
} from '@/openapi-types';
import KeysTable from '@/components/tokens/KeysTable.vue';
import TokenLoggingButton from '@/components/tokens/TokenLoggingButton.vue';
import SigningKeyAddDialog from '@/components/signingKeys/SigningKeyAddDialog.vue';

export default Vue.extend({
  components: {
    SigningKeyAddDialog,
    KeysTable,
    TokenLoggingButton,
  },
  props: {
    token: {
      type: Object as Prop<Token>,
      required: true,
    },
    configurationType: {
      type: String as Prop<ConfigurationType>,
      required: true,
    },
    loadingKeys: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colors: Colors,
      showAddKeyDialog: false,
    };
  },
  computed: {
    ...mapState(userStore, ['hasPermission']),
    ...mapState(tokenStore, {
      isExpanded: 'tokenExpanded',
    }),
    showAddKey(): boolean {
      return this.hasPermission(Permissions.GENERATE_SIGNING_KEY);
    },
    canAddKey(): boolean {
      return (
        this.token.possible_actions?.includes(
          ConfigurationType.INTERNAL == this.configurationType
            ? PossibleTokenAction.GENERATE_INTERNAL_KEY
            : PossibleTokenAction.GENERATE_EXTERNAL_KEY,
        ) || false
      );
    },
    tokenStatusClass(): string {
      return this.token.logged_in ? 'logged-out' : 'logged-in';
    },
    tokenStatusColor(): string {
      return this.token.logged_in ? this.colors.Black100 : this.colors.Purple20;
    },
    signingKeys(): ConfigurationSigningKey[] {
      return (
        this.token.configuration_signing_keys?.filter(
          (key) => key.source_type === this.configurationType,
        ) || []
      );
    },
  },
  methods: {
    ...mapActions(tokenStore, [
      'setSelectedToken',
      'setTokenHidden',
      'setTokenExpanded',
    ]),
    addKey(): void {
      this.showAddKeyDialog = false;
      this.$emit('update-keys', 'add');
    },
    tokenNameClick(): void {
      this.isExpanded(this.token.id)
        ? this.descClose(this.token.id)
        : this.descOpen(this.token.id);
    },
    descClose(tokenId: string) {
      this.setTokenHidden(tokenId);
    },
    descOpen(tokenId: string) {
      this.setTokenExpanded(tokenId);
    },
  },
});
