



































































import Vue from 'vue';
import { mapActions, mapStores } from 'pinia';
import { notificationsStore } from '@/store/modules/notifications';
import { useGlobalGroupsStore } from '@/store/modules/global-groups';
import { GroupMemberListView } from '@/openapi-types';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { toIdentifier } from '@/util/helpers';

export default Vue.extend({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    groupCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      memberCode: '',
      deleting: false,
      member: null as GroupMemberListView | null,
    };
  },
  computed: {
    ...mapStores(useGlobalGroupsStore),
    dialog(): boolean {
      return this.member != null;
    },
    identifier(): string {
      if (this.member == null || this.member.client_id === undefined) {
        return '';
      }
      return toIdentifier(this.member.client_id);
    },
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    open(member: GroupMemberListView) {
      this.member = member;
    },
    close() {
      this.member = null;
      this.memberCode = '';
    },
    deleteGroupMember() {
      if (this.member == null) {
        return;
      }
      this.deleting = true;
      this.globalGroupStore
        .deleteGroupMember(
          this.groupCode,
          this.member.client_id.encoded_id || '',
        )
        .then(() => this.$emit('deleted'))
        .then(() =>
          this.showSuccess(
            this.$t('globalGroup.dialog.deleteMember.success', {
              identifier: this.identifier,
            }),
          ),
        )
        .then(() => this.close())
        .catch((error) => this.showError(error))
        .finally(() => (this.deleting = false));
    },
  },
});
