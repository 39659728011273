


































































































































































import Vue from 'vue';
import { mapActions, mapState } from 'pinia';
import { notificationsStore } from '@/store/modules/notifications';
import { toClipboard } from '@/util/helpers';
import { Notification } from '@/ui-types';

export default Vue.extend({
  // Component for contextual notifications
  computed: {
    ...mapState(notificationsStore, ['errorNotifications']),
  },
  methods: {
    ...mapActions(notificationsStore, ['deleteNotification']),

    closeError(id: number): void {
      this.deleteNotification(id);
    },
    copyId(notification: Notification): void {
      const id = notification.errorId;
      if (id) {
        toClipboard(id);
      }
    },

    routeAction(notification: Notification): void {
      if (notification.action) {
        this.$router.push({
          name: notification.action.route,
        });
      }
      this.closeError(notification.timeAdded);
    },
  },
});
