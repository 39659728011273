var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.opened)?_c('v-dialog',{attrs:{"value":_vm.opened,"width":"824","scrollable":"","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel.apply(null, arguments)}}},[_c('v-card',{staticClass:"xrd-card"},[_c('v-card-title',[_vm._t("title",function(){return [_c('span',{staticClass:"dialog-title-text"},[_vm._v(" "+_vm._s(_vm.$t('globalGroup.dialog.addMembers.title'))+" ")])]}),_c('v-spacer'),_c('xrd-close-button',{attrs:{"id":"dlg-close-x"},on:{"click":function($event){return _vm.cancel()}}})],2),_c('v-card-text',{staticClass:"elevation-0",staticStyle:{"height":"500px"}},[_c('v-text-field',{staticClass:"search-input",attrs:{"data-test":"member-subsystem-search-field","append-icon":"icon-Search","single-line":"","hide-details":"","autofocus":"","label":_vm.$t('systemSettings.selectSubsystem.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-0 data-table",attrs:{"data-test":"select-members-list","item-key":"client_id.encoded_id","show-select":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.selectableClients,"server-items-length":_vm.totalItems,"options":_vm.pagingSortingOptions,"loader-height":2,"footer-props":{ itemsPerPageOptions: [10, 25, 50] }},on:{"update:options":[function($event){_vm.pagingSortingOptions=$event},_vm.changeOptions]},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"data-test":"members-checkbox","ripple":false,"value":isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"item.member_name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.member_name))])]}},{key:"item.client_id.member_code",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-test":"code"}},[_vm._v(_vm._s(item.client_id.member_code))])]}},{key:"item.client_id.member_class",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-test":"class"}},[_vm._v(_vm._s(item.client_id.member_class))])]}},{key:"item.client_id.subsystem_code",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-test":"subsystem"}},[_vm._v(_vm._s(item.client_id.subsystem_code))])]}},{key:"item.client_id.instance_id",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-test":"instance"}},[_vm._v(_vm._s(item.client_id.instance_id))])]}},{key:"item.client_id.type",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.client_id.type))])]}}],null,true),model:{value:(_vm.selectedClients),callback:function ($$v) {_vm.selectedClients=$$v},expression:"selectedClients"}})],1),_c('v-card-actions',{staticClass:"xrd-card-actions"},[_c('v-spacer'),_c('xrd-button',{staticClass:"button-margin",attrs:{"data-test":"cancel-button","outlined":"","disabled":_vm.adding},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.$t('action.cancel'))+" ")]),_c('xrd-button',{attrs:{"data-test":"member-subsystem-add-button","loading":_vm.adding,"disabled":_vm.anyClientsSelected},on:{"click":_vm.addMembers}},[_vm._v(" "+_vm._s(_vm.$t('action.add'))+" ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }