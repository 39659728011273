
































/**
 * View for 'management requests' tab
 */
import Vue from 'vue';

export default Vue.extend({});
