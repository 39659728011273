







































































/**
 * View for 'security server clients' tab
 */
import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import { Client } from '@/openapi-types';
import { mapActions, mapState, mapStores } from 'pinia';
import { clientStore } from '@/store/modules/clients';
import { notificationsStore } from '@/store/modules/notifications';
import { Permissions, RouteName } from '@/global';
import { userStore } from '@/store/modules/user';
import { toMemberId } from '@/util/helpers';

export default Vue.extend({
  name: 'SecurityServerClients',
  props: {
    serverId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      loading: false,
      clients: [] as Client[],
    };
  },
  computed: {
    ...mapStores(clientStore),
    ...mapState(userStore, ['hasPermission']),
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('global.memberName') as string,
          align: 'start',
          value: 'member_name',
          class: 'xrd-table-header clients-table-header-name',
        },
        {
          text: this.$t('global.class') as string,
          align: 'start',
          value: 'client_id.member_class',
          class: 'xrd-table-header clients-table-header-class',
        },
        {
          text: this.$t('global.code') as string,
          align: 'start',
          value: 'client_id.member_code',
          class: 'xrd-table-header clients-table-header-code',
        },
        {
          text: this.$t('global.subsystem') as string,
          align: 'start',
          value: 'client_id.subsystem_code',
          class: 'xrd-table-header clients-table-header-subsystem',
        },
      ];
    },
    hasPermissionToMemberDetails(): boolean {
      return this.hasPermission(Permissions.VIEW_MEMBER_DETAILS);
    },
  },
  created() {
    this.loading = true;
    this.clientStore
      .getBySecurityServerId(this.serverId)
      .then((resp) => {
        this.clients = resp;
      })
      .catch((error) => {
        this.showError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    toMemberDetails(client: Client): void {
      this.$router.push({
        name: RouteName.MemberDetails,
        params: {
          memberid: toMemberId(client.client_id),
          backTo: this.$router.currentRoute.path,
        },
      });
    },
  },
});
