































































import Vue from 'vue';
import { RouteName, Timeouts } from '@/global';
import { get } from '@/util/api';
import { mapActions, mapState } from 'pinia';
import { userStore } from '@/store/modules/user';
import { systemStore } from '@/store/modules/system';
import { useAlerts } from '@/store/modules/alerts';

export default Vue.extend({
  data() {
    return {
      sessionPollInterval: 0,
      alertsPollInterval: 0,
    };
  },
  computed: {
    ...mapState(userStore, ['isSessionAlive']),
    showDialog(): boolean {
      return this.isSessionAlive === false;
    },
  },
  created() {
    this.sessionPollInterval = setInterval(
      () => this.pollSessionStatus(),
      Timeouts.POLL_SESSION_TIMEOUT,
    );
    this.pollSessionStatus();
    this.fetchSystemStatus();
    this.checkAlerts();
    // Set interval to poll backend for session
  },
  methods: {
    ...mapActions(userStore, ['setSessionAlive']),
    ...mapActions(userStore, { storeLogout: 'logout' }),
    ...mapActions(useAlerts, ['checkAlerts']),
    ...mapActions(systemStore, [
      'fetchSystemStatus',
      'updateCentralServerAddress',
    ]),
    pollSessionStatus() {
      return get('/notifications/session-status')
        .then(() => {
          // Fetch any statuses from backend that are
          // needed with POLL_SESSION_TIMEOUT periods
          this.checkAlerts();
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            this.setSessionAlive(false);
            clearInterval(this.sessionPollInterval);
          }
        });
    },
    logout(): void {
      this.storeLogout();
      this.$router.replace({ name: RouteName.Login });
    },
  },
});
