var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('header',{staticClass:"table-toolbar align-fix mt-8 pl-0"},[_c('div',{staticClass:"xrd-title-search align-fix mt-0 pt-0"},[_c('div',{staticClass:"xrd-view-title align-fix"},[_c('i18n',{attrs:{"path":"globalGroup.groupMembers"},scopedSlots:_vm._u([{key:"memberCount",fn:function(){return [_c('span',{attrs:{"data-test":"member-count"}},[_vm._v(_vm._s(_vm.memberCount))])]},proxy:true}])})],1),_c('xrd-search',{staticClass:"margin-fix",model:{value:(_vm.filter.query),callback:function ($$v) {_vm.$set(_vm.filter, "query", $$v)},expression:"filter.query"}}),_c('v-icon',{staticClass:"ml-4 mt-1",attrs:{"color":"primary"},on:{"click":function($event){_vm.showFilterDialog = true}}},[_vm._v("mdi-filter-outline ")])],1),_c('div',{staticClass:"only-pending mt-0"},[(_vm.allowAddAndRemoveGroupMembers)?_c('xrd-button',{attrs:{"data-test":"add-member-button"},on:{"click":function($event){return _vm.$refs.addDialog.open()}}},[_c('v-icon',{staticClass:"xrd-large-button-icon"},[_vm._v("mdi-plus-circle")]),_vm._v(" "+_vm._s(_vm.$t('globalGroup.addMembers'))+" ")],1):_vm._e()],1)]),_c('v-data-table',{staticClass:"elevation-0 data-table",attrs:{"data-test":"global-group-members","item-key":"id","loading":_vm.loading,"headers":_vm.membersHeaders,"items":_vm.globalGroupStore.members,"search":_vm.filter.query,"must-sort":true,"options":_vm.pagingSortingOptions,"server-items-length":_vm.globalGroupStore.pagingOptions.total_items,"loader-height":2,"footer-props":{ itemsPerPageOptions: [10, 25, 50] }},on:{"update:options":[function($event){_vm.pagingSortingOptions=$event},_vm.changeOptions]},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"member-name xrd-clickable"},[_c('xrd-icon-base',{staticClass:"mr-4"},[_c('XrdIconFolderOutline')],1),_c('div',{attrs:{"data-test":"member-name"}},[_vm._v(_vm._s(item.name))])],1)]}},{key:"item.button",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cs-table-actions-wrap"},[(_vm.allowAddAndRemoveGroupMembers)?_c('xrd-button',{attrs:{"data-test":"delete-member-button","text":"","outlined":false},on:{"click":function($event){return _vm.$refs.deleteDialog.open(item)}}},[_vm._v(_vm._s(_vm.$t('action.remove'))+" ")]):_vm._e()],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.client_id.type))])]}},{key:"item.instance",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"data-test":"instance"}},[_vm._v(_vm._s(item.client_id.instance_id))])]}},{key:"item.class",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"data-test":"class"}},[_vm._v(_vm._s(item.client_id.member_class))])]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"data-test":"code"}},[_vm._v(_vm._s(item.client_id.member_code))])]}},{key:"item.subsystem",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"data-test":"subsystem"}},[_vm._v(_vm._s(item.client_id.subsystem_code))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.created_at)))])]}}],null,true)}),_c('group-members-filter-dialog',{attrs:{"group-code":_vm.groupCode,"dialog":_vm.showFilterDialog,"cancel-button-text":"action.cancel"},on:{"cancel":_vm.cancelFilter,"apply":_vm.applyFilter}}),_c('add-group-members-dialog',{ref:"addDialog",attrs:{"group-code":_vm.groupCode},on:{"added":_vm.refreshList}}),_c('delete-group-member-dialog',{ref:"deleteDialog",attrs:{"group-code":_vm.groupCode},on:{"deleted":_vm.refreshList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }