/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ManagementRequestType {
    AUTH_CERT_REGISTRATION_REQUEST = 'AUTH_CERT_REGISTRATION_REQUEST',
    CLIENT_REGISTRATION_REQUEST = 'CLIENT_REGISTRATION_REQUEST',
    OWNER_CHANGE_REQUEST = 'OWNER_CHANGE_REQUEST',
    CLIENT_DELETION_REQUEST = 'CLIENT_DELETION_REQUEST',
    AUTH_CERT_DELETION_REQUEST = 'AUTH_CERT_DELETION_REQUEST',
}