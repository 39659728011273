




































































import Vue from 'vue';
import { mapActions, mapState, mapStores } from 'pinia';
import { clientStore } from '@/store/modules/clients';
import { systemStore } from '@/store/modules/system';
import { notificationsStore } from '@/store/modules/notifications';
import { subsystemStore } from '@/store/modules/subsystems';
import { memberStore } from '@/store/modules/members';
import { toIdentifier } from '@/util/helpers';
import { Client } from '@/openapi-types';

export default Vue.extend({
  name: 'UnregisterMemberSubsystemDialog',
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    subsystemCode: {
      type: String,
      required: true,
    },
    serverCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapStores(clientStore, memberStore, subsystemStore),
    ...mapState(systemStore, ['getSystemStatus']),
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    cancel(): void {
      this.$emit('cancel');
    },
    unregisterSubsystem(): void {
      this.loading = true;
      const currentMember = this.memberStore.$state.currentMember;
      this.subsystemStore
        .unregisterById(
          toIdentifier(currentMember.client_id) + ':' + this.subsystemCode,
          toIdentifier(currentMember.client_id) + ':' + this.serverCode,
        )
        .then(() => {
          this.showSuccess(
            this.$t(
              'members.member.subsystems.subsystemSuccessfullyUnregistered',
              {
                subsystemCode: this.subsystemCode,
                serverCode: this.serverCode,
              },
            ),
          );
          this.$emit('unregisteredSubsystem');
        })
        .catch((error) => {
          this.showError(error);
          this.$emit('cancel');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
