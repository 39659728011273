





































import Vue from 'vue';
import { mapActions, mapStores } from 'pinia';
import { trustedAnchorStore } from '@/store/modules/trusted-anchors';
import { notificationsStore } from '@/store/modules/notifications';

export default Vue.extend({
  props: {
    hash: {
      type: String,
      required: true,
    },
    identifier: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      opened: false,
      deleting: false,
    };
  },
  computed: {
    ...mapStores(trustedAnchorStore),
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    open() {
      this.opened = true;
    },
    deleteAnchor() {
      this.deleting = true;
      this.trustedAnchorStore
        .deleteTrustedAnchor(this.hash)
        .then(() => this.$emit('deleted'))
        .then(() => (this.opened = false))
        .then(() =>
          this.showSuccess(
            this.$t('globalConf.trustedAnchor.dialog.delete.success'),
          ),
        )
        .catch((error) => this.showError(error))
        .finally(() => (this.deleting = false));
    },
  },
});
