
































import Vue from 'vue';
import { mapStores } from 'pinia';
import { useCertificationServiceStore } from '@/store/modules/trust-services';
import CertificateDetails from '@/components/certificate/CertificateDetails.vue';
import { CertificateDetails as CertificateDetailsType } from '@/openapi-types';

export default Vue.extend({
  name: 'CertificationServiceCertificate',
  components: { CertificateDetails },
  props: {
    certificationServiceId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      certificateDetails: null as CertificateDetailsType | null,
    };
  },
  computed: {
    ...mapStores(useCertificationServiceStore),
  },
  created() {
    this.certificationServiceStore
      .getCertificate(this.certificationServiceId)
      .then((resp) => (this.certificateDetails = resp.data));
  },
});
