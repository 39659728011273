



























































import Vue from 'vue';
import { mapActions, mapStores } from 'pinia';
import { useGlobalGroupsStore } from '@/store/modules/global-groups';
import { notificationsStore } from '@/store/modules/notifications';

export default Vue.extend({
  name: 'AddGroupDialog',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      code: '',
      description: '',
    };
  },
  computed: {
    ...mapStores(useGlobalGroupsStore, notificationsStore),
    formReady(): boolean {
      return !!(
        this.code &&
        this.code.length > 0 &&
        this.description.length > 0
      );
    },
  },

  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    cancel(): void {
      this.clearForm();
      this.$emit('cancel');
    },
    save(): void {
      this.loading = true;
      this.globalGroupStore
        .add({ code: this.code, description: this.description })
        .then(() => {
          this.notificationsStoreStore.showSuccess(
            this.$t('globalResources.globalGroupSuccessfullyAdded'),
          );
          this.clearForm();
          this.$emit('group-added');
        })
        .catch((error) => {
          this.notificationsStoreStore.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearForm(): void {
      this.code = '';
      this.description = '';
    },
  },
});
