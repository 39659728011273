/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * request source
 */
export enum ManagementRequestOrigin {
    CENTER = 'CENTER',
    SECURITY_SERVER = 'SECURITY_SERVER',
}