



































import CertificateDetails from '@/components/certificate/CertificateDetails.vue';
import { SecurityServerAuthenticationCertificateDetails } from '@/openapi-types';
import { securityServerAuthCertStore } from '@/store/modules/security-servers-authentication-certificates';
import { mapStores } from 'pinia';
import Vue from 'vue';

export default Vue.extend({
  name: 'SecurityServerAuthenticationCertificate',
  components: { CertificateDetails },
  props: {
    authenticationCertificateId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapStores(securityServerAuthCertStore),
    securityServerAuthenticationCertificate():
      | SecurityServerAuthenticationCertificateDetails
      | undefined {
      return this.securityServerAuthCertStore.authenticationCertificates.find(
        (authCert) => authCert.id === this.authenticationCertificateId,
      );
    },
  },
});
