




































































import Vue from 'vue';
import TabsBase from '@/components/layout/TabsBase.vue';
import AlertsContainer from '@/components/ui/AlertsContainer.vue';
import { mapState } from 'pinia';
import { userStore } from '@/store/modules/user';

export default Vue.extend({
  components: {
    TabsBase,
    AlertsContainer,
  },
  computed: {
    ...mapState(userStore, ['getFirstAllowedTab']),
  },
  methods: {
    home(): void {
      this.$router.replace({
        name: this.getFirstAllowedTab.to.name,
      });
    },
    goBack(): void {
      this.$router.go(-2); // needs to be two steps
    },
  },
});
