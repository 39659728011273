







































import Vue from 'vue';

import { Colors } from '@/global';

export default Vue.extend({
  props: {
    backTo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      colors: Colors,
    };
  },
});
