
































































































import Vue from 'vue';
import { RouteName } from '@/global';
import AddMemberDialog from '@/views/Members/Member/AddMemberDialog.vue';
import { DataTableHeader } from 'vuetify';
import { userStore } from '@/store/modules/user';
import { clientStore } from '@/store/modules/clients';
import { mapState } from 'pinia';
import { Permissions } from '@/global';
import { mapActions, mapStores } from 'pinia';
import { DataOptions } from 'vuetify';
import { debounce, toIdentifier } from '@/util/helpers';
import { notificationsStore } from '@/store/modules/notifications';
import { Client } from '@/openapi-types';

// To provide the Vue instance to debounce
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let that: any;

export default Vue.extend({
  name: 'MemberList',
  components: {
    AddMemberDialog,
  },
  data() {
    return {
      search: '',
      loading: false,
      showOnlyPending: false,
      pagingSortingOptions: {} as DataOptions,
      showAddMemberDialog: false,
    };
  },
  computed: {
    ...mapStores(clientStore),
    ...mapState(userStore, ['hasPermission']),
    headers(): DataTableHeader[] {
      return [
        {
          text:
            (this.$t('global.memberName') as string) +
            ' (' +
            this.clientStore.clients?.length +
            ')',
          align: 'start',
          value: 'member_name',
          class: 'xrd-table-header members-table-header-name',
        },
        {
          text: this.$t('global.memberClass') as string,
          align: 'start',
          value: 'client_id.member_class',
          class: 'xrd-table-header members-table-header-class',
        },
        {
          text: this.$t('global.memberCode') as string,
          align: 'start',
          value: 'client_id.member_code',
          class: 'xrd-table-header members-table-header-code',
        },
      ];
    },
    hasPermissionToMemberDetails(): boolean {
      return this.hasPermission(Permissions.VIEW_MEMBER_DETAILS);
    },
    hasPermissionToAddMember(): boolean {
      return this.hasPermission(Permissions.ADD_NEW_MEMBER);
    },
  },
  watch: {
    search: function (newSearch, oldSearch) {
      this.debouncedFetchClients();
    },
  },
  created() {
    that = this;
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    hideAddMemberDialog(): void {
      this.showAddMemberDialog = false;
    },
    hideAddMemberDialogAndRefetch(): void {
      this.hideAddMemberDialog();
      this.fetchClients(this.pagingSortingOptions);
    },
    debouncedFetchClients: debounce(() => {
      // Debounce is used to reduce unnecessary api calls
      that.fetchClients(that.pagingSortingOptions);
    }, 600),
    toDetails(member: Client): void {
      this.$router.push({
        name: RouteName.MemberDetails,
        params: {
          memberid: toIdentifier(member.client_id),
          backTo: this.$router.currentRoute.path,
        },
      });
    },
    changeOptions: async function () {
      this.fetchClients(this.pagingSortingOptions);
    },
    fetchClients: async function (options: DataOptions) {
      this.loading = true;

      try {
        await this.clientStore.find(options, this.search);
      } catch (error: unknown) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
