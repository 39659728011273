




















































import Vue from 'vue';
import { mapActions, mapStores } from 'pinia';
import { memberStore } from '@/store/modules/members';
import { Client } from '@/openapi-types';
import { notificationsStore } from '@/store/modules/notifications';
import { toIdentifier } from '@/util/helpers';

export default Vue.extend({
  name: 'EditMemberNameDialog',
  props: {
    member: {
      type: Object as () => Client,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      newMemberName: this.member.member_name,
      oldMemberName: this.member.member_name,
    };
  },
  computed: {
    ...mapStores(memberStore),
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    cancelEdit(): void {
      this.$emit('cancel');
    },
    saveNewMemberName(): void {
      this.loading = true;
      this.memberStore
        .editMemberName(toIdentifier(this.member.client_id), {
          member_name: this.newMemberName,
        })
        .then(() => {
          this.showSuccess(this.$t('members.member.details.memberNameSaved'));
          this.$emit('nameChanged');
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
