
























































import Vue, { VueConstructor } from 'vue';
import { mapActions, mapState, mapStores } from 'pinia';
import { userStore } from '@/store/modules/user';
import { Permissions } from '@/global';
import { FileUploadResult } from '@niis/shared-ui';
import UploadTrustedAnchorDialog from './UploadTrustedAnchorDialog.vue';
import { trustedAnchorStore } from '@/store/modules/trusted-anchors';
import { TrustedAnchor } from '@/openapi-types';
import { notificationsStore } from '@/store/modules/notifications';

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        dialog: InstanceType<typeof UploadTrustedAnchorDialog>;
      };
    }
  >
).extend({
  components: { UploadTrustedAnchorDialog },
  data() {
    return {
      uploading: false,
      preview: null as TrustedAnchor | null,
      file: null as File | null,
    };
  },
  computed: {
    ...mapState(userStore, ['hasPermission']),
    ...mapStores(trustedAnchorStore),
    canUpload(): boolean {
      return this.hasPermission(Permissions.UPLOAD_TRUSTED_ANCHOR);
    },
  },
  methods: {
    ...mapActions(notificationsStore, ['showSuccess', 'showError']),
    onFileUploaded(result: FileUploadResult) {
      this.file = result.file;
      this.uploading = true;
      this.trustedAnchorStore
        .previewTrustedAnchors(result.file)
        .then((resp) => (this.preview = resp.data))
        .then(() => this.$refs.dialog.open())
        .catch((error) => this.showError(error))
        .finally(() => (this.uploading = false));
    },
    clear(uploaded = false) {
      if (uploaded) {
        this.$emit('uploaded');
      }
      this.preview = null;
      this.file = null;
    },
  },
});
