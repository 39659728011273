
































/**
 * View for 'global resources'
 */
import Vue from 'vue';
import GlobalGroupsList from '@/components/globalGroups/GlobalGroupsList.vue';

export default Vue.extend({
  name: 'GlobalResourcesList',
  components: {
    GlobalGroupsList,
  },
});
