




















































import { Prop } from 'vue/types/options';
import Vue from 'vue';

export default Vue.extend({
  name: 'GlobalGroupEditDescriptionDialog',
  props: {
    groupCode: {
      type: String,
      required: true,
    },
    groupDescription: {
      type: String,
      required: true,
    },
    showDialog: {
      type: Boolean as Prop<boolean>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      newDescription: this.groupDescription,
    };
  },
  methods: {
    cancelEdit(): void {
      this.$emit('cancel');
    },
    saveDescription(): void {
      this.loading = true;
      this.$emit('edit', this.newDescription);
    },
  },
});
