




































import Vue from 'vue';
import ContextualAlerts from './ContextualAlerts.vue';
import GlobalAlerts from './GlobalAlerts.vue';
import InitNotification from '@/components/ui/InitNotification.vue';

export default Vue.extend({
  components: {
    InitNotification,
    ContextualAlerts,
    GlobalAlerts,
  },
});
