


































































































import Vue from 'vue';
import AddCertificationServiceDialog from '@/components/certificationServices/AddCertificationServiceDialog.vue';
import { DataTableHeader } from 'vuetify';
import { mapActions, mapState, mapStores } from 'pinia';
import { notificationsStore } from '@/store/modules/notifications';
import { useCertificationServiceStore } from '@/store/modules/trust-services';
import { userStore } from '@/store/modules/user';
import { Permissions, RouteName } from '@/global';
import {
  ApprovedCertificationService,
  ApprovedCertificationServiceListItem,
  CertificationServiceFileAndSettings,
} from '@/openapi-types';
import TimestampingServicesList from '@/components/timestampingServices/TimestampingServicesList.vue';

export default Vue.extend({
  name: 'TrustServiceList',
  components: {
    AddCertificationServiceDialog,
    TimestampingServicesList,
  },
  data() {
    return {
      search: '' as string,
      loading: false,
      showAddCSDialog: false,
      permissions: Permissions,
    };
  },
  computed: {
    ...mapStores(useCertificationServiceStore, notificationsStore),
    ...mapState(userStore, ['hasPermission']),
    certificationServices(): ApprovedCertificationServiceListItem[] {
      return this.certificationServiceStore.certificationServices;
    },
    hasPermissionToDetails(): boolean {
      return this.hasPermission(Permissions.VIEW_APPROVED_CA_DETAILS);
    },
    showAddCSButton(): boolean {
      return this.hasPermission(Permissions.ADD_APPROVED_CA);
    },
    showTsaList(): boolean {
      return this.hasPermission(Permissions.VIEW_APPROVED_TSAS);
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('trustServices.approvedCertificationService') as string,
          align: 'start',
          value: 'name',
          class: 'xrd-table-header ts-table-header-server-code text-uppercase',
        },
        {
          text: this.$t('trustServices.validFrom') as string,
          align: 'start',
          value: 'not_before',
          class: 'xrd-table-header ts-table-header-valid-from text-uppercase',
        },
        {
          text: this.$t('trustServices.validTo') as string,
          align: 'start',
          value: 'not_after',
          class: 'xrd-table-header ts-table-header-valid-to text-uppercase',
        },
      ];
    },
  },
  created() {
    this.fetchCertificationServices();
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    fetchCertificationServices(): void {
      this.loading = true;
      this.certificationServiceStore
        .fetchAll()
        .finally(() => (this.loading = false));
    },
    hideAddCSDialog(): void {
      this.showAddCSDialog = false;
    },
    toDetails(certificationService: ApprovedCertificationService): void {
      this.$router.push({
        name: RouteName.CertificationServiceDetails,
        params: { certificationServiceId: String(certificationService.id) },
      });
    },
    addCertificationService(
      addCertificationService: CertificationServiceFileAndSettings,
    ): void {
      this.certificationServiceStore
        .add(addCertificationService)
        .then(() => {
          this.showSuccess(this.$t('trustServices.certImportedSuccessfully'));
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => this.hideAddCSDialog());
    },
  },
});
