





















































/**
 * View for 'backup and restore' tab
 */
import Vue from 'vue';
import { Colors, Permissions } from '@/global';
import { mapActions, mapState, mapStores } from 'pinia';
import { useBackupsStore } from '@/store/modules/backups';
import { notificationsStore } from '@/store/modules/notifications';
import VueI18n from 'vue-i18n';
import Values = VueI18n.Values;
import { Backup } from '@/openapi-types';
import { userStore } from '@/store/modules/user';

export default Vue.extend({
  data() {
    return {
      filter: '',
      loading: false,
      colors: Colors,
    };
  },
  computed: {
    ...mapStores(useBackupsStore),
    ...mapState(userStore, ['hasPermission']),
    canBackup(): boolean {
      return this.hasPermission(Permissions.BACKUP_CONFIGURATION);
    },
    backups(): Backup[] {
      return this.backupStore.backups;
    },
  },
  created() {
    this.fetchBackups();
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    backupHandler() {
      return {
        showSuccess: this.displaySuccess,
        showError: this.showError,
        delete: this.deleteBackup,
        download: this.downloadBackup,
        upload: this.uploadBackup,
        create: this.createBackup,
        restore: this.restoreFromBackup,
      };
    },
    fetchBackups() {
      this.loading = true;
      this.backupStore
        .getBackups()
        .catch((error) => this.showError(error))
        .finally(() => (this.loading = false));
    },
    createBackup() {
      return this.backupStore.createBackup().then((resp) => resp.data);
    },
    deleteBackup(filename: string) {
      return this.backupStore.deleteBackup(filename);
    },
    restoreFromBackup(filename: string) {
      return this.backupStore.restoreFromBackup(filename);
    },
    downloadBackup(filename: string) {
      return this.backupStore.downloadBackup(filename);
    },
    uploadBackup(backupFile: File, ignoreWarnings = false) {
      return this.backupStore.uploadBackup(backupFile, ignoreWarnings);
    },
    displaySuccess(textKey: string, data: Values = {}) {
      this.showSuccess(this.$t(textKey, data));
    },
  },
});
