/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * an action to change state or edit tokens
 */
export enum PossibleTokenAction {
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    GENERATE_INTERNAL_KEY = 'GENERATE_INTERNAL_KEY',
    GENERATE_EXTERNAL_KEY = 'GENERATE_EXTERNAL_KEY',
    CHANGE_PIN = 'CHANGE_PIN',
    EDIT_FRIENDLY_NAME = 'EDIT_FRIENDLY_NAME',
}