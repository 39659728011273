var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('xrd-simple-dialog',{attrs:{"title":"securityServers.dialogs.deleteAddress.title","data-test":"security-server-delete-dialog","save-button-text":"action.delete","dialog":_vm.showDialog,"scrollable":false,"show-close":true,"loading":_vm.loading,"disable-save":invalid},on:{"save":_vm.deleteSecurityServer,"cancel":_vm.close}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('v-card-text',{staticClass:"pt-4",attrs:{"data-test":"delete-subsystem"}},[_c('i18n',{attrs:{"path":"securityServers.dialogs.deleteAddress.areYouSure"},scopedSlots:_vm._u([{key:"securityServer",fn:function(){return [_c('b',[_vm._v(_vm._s(_vm.serverCode))])]},proxy:true}],null,true)})],1),_c('div',{staticClass:"pt-4 dlg-input-width"},[_c('validation-provider',{ref:"serverCodeInput",attrs:{"name":"serverCode","rules":{
            required: true,
            is: _vm.serverCode,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"serverCode","outlined":"","label":_vm.$t('securityServers.dialogs.deleteAddress.enterCode'),"autofocus":"","data-test":"verify-server-code","error-messages":errors},model:{value:(_vm.offeredCode),callback:function ($$v) {_vm.offeredCode=$$v},expression:"offeredCode"}})]}}],null,true)})],1)],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }