














































import Vue from 'vue';

export default Vue.extend({
  props: {
    blockTitleKey: {
      type: String,
      required: true,
    },
  },

  computed: {},

  methods: {},
});
