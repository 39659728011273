




































































































































import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import { mapActions, mapStores } from 'pinia';
import { useOcspResponderStore } from '@/store/modules/trust-services';
import { notificationsStore } from '@/store/modules/notifications';
import AddOcspResponderDialog from '@/components/ocspResponders/AddOcspResponderDialog.vue';
import {
  ApprovedCertificationService,
  CertificateAuthority,
  OcspResponder,
} from '@/openapi-types';
import EditOcspResponderDialog from '@/components/ocspResponders/EditOcspResponderDialog.vue';
import { RouteName } from '@/global';

export default Vue.extend({
  name: 'OcspRespondersList',
  components: { EditOcspResponderDialog, AddOcspResponderDialog },
  props: {
    ca: {
      type: [
        Object as () => ApprovedCertificationService,
        Object as () => CertificateAuthority,
      ],
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      showAddOcspResponderDialog: false,
      showEditOcspResponderDialog: false,
      selectedOcspResponder: undefined as undefined | OcspResponder,
      confirmDelete: false,
      deletingOcspResponder: false,
    };
  },
  computed: {
    ...mapStores(useOcspResponderStore),
    ocspResponders(): OcspResponder[] {
      return this.ocspResponderServiceStore.currentOcspResponders;
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t(
            'trustServices.trustService.ocspResponders.url',
          ) as string,
          align: 'start',
          value: 'url',
          class: 'xrd-table-header mr-table-header-id',
        },
        {
          text: '',
          value: 'button',
          sortable: false,
          class: 'xrd-table-header mr-table-header-buttons',
        },
      ];
    },
  },
  created() {
    this.ocspResponderServiceStore.loadByCa(this.ca);
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    hideAddOcspResponderDialog() {
      this.showAddOcspResponderDialog = false;
    },
    hideEditOcspResponderDialog() {
      this.showEditOcspResponderDialog = false;
    },
    hideEditOcspResponderDialogAndRefetch() {
      this.showEditOcspResponderDialog = false;
      this.fetchOcspResponders();
    },
    openDeleteConfirmationDialog(ocspResponder: OcspResponder): void {
      this.selectedOcspResponder = ocspResponder;
      this.confirmDelete = true;
    },
    openEditOcspResponderDialog(ocspResponder: OcspResponder): void {
      this.selectedOcspResponder = ocspResponder;
      this.showEditOcspResponderDialog = true;
    },
    navigateToCertificateDetails(ocspResponder: OcspResponder) {
      this.$router.push({
        name: RouteName.OcspResponderCertificateDetails,
        params: {
          ocspResponderId: String(ocspResponder.id),
        },
      });
    },
    fetchOcspResponders(): void {
      this.ocspResponderServiceStore.fetchOcspResponders();
    },
    deleteOcspResponder(): void {
      if (!this.selectedOcspResponder) return;

      this.deletingOcspResponder = true;
      this.ocspResponderServiceStore
        .deleteOcspResponder(this.selectedOcspResponder.id)
        .then(() => {
          this.showSuccess(
            this.$t('trustServices.trustService.ocspResponders.delete.success'),
          );
          this.confirmDelete = false;
          this.deletingOcspResponder = false;
          this.fetchOcspResponders();
        })
        .catch((error) => {
          this.showError(error);
        });
    },
  },
});
