















































import Vue from 'vue';
import CertificateHash from '@/components/certificate/CertificateHash.vue';
import CertificateInfo from '@/components/certificate/CertificateInfo.vue';
import { CertificateDetails } from '@/openapi-types';

export default Vue.extend({
  name: 'CertificateDetails',
  components: {
    CertificateInfo,
    CertificateHash,
  },
  props: {
    certificateDetails: {
      type: Object as () => CertificateDetails,
      required: true,
    },
  },
  methods: {
    close(): void {
      this.$router.go(-1);
    },
  },
});
