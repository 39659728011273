






































import Vue from 'vue';
import PageNavigation, {
  PageNavigationTab,
} from '@/components/layout/PageNavigation.vue';
import { Colors, Permissions, RouteName } from '@/global';
import { mapActions, mapStores } from 'pinia';
import { useSecurityServerStore } from '@/store/modules/security-servers';
import { notificationsStore } from '@/store/modules/notifications';
import DetailsView from '@/components/ui/DetailsView.vue';

/**
 * Wrapper component for a security server view
 */
export default Vue.extend({
  components: { DetailsView, PageNavigation },
  props: {
    serverId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      colors: Colors,
    };
  },
  computed: {
    ...mapStores(useSecurityServerStore),
    securityServerCode(): string {
      return (
        this.securityServerStore?.currentSecurityServer?.server_id
          .server_code || ''
      );
    },
    securityServerNavigationTabs(): PageNavigationTab[] {
      return [
        {
          key: 'security-server-details-tab-button',
          name: 'securityServers.securityServer.tabs.details',
          to: {
            name: RouteName.SecurityServerDetails,
          },
          permissions: [Permissions.VIEW_SECURITY_SERVER_DETAILS],
        },

        {
          key: 'security-server-clients-tab-button',
          name: 'securityServers.securityServer.tabs.clients',
          to: {
            name: RouteName.SecurityServerClients,
          },
          permissions: [Permissions.VIEW_SECURITY_SERVER_DETAILS],
        },

        {
          key: 'security-server-auth-certs-tab-button',
          name: 'securityServers.securityServer.tabs.authCertificates',
          to: {
            name: RouteName.SecurityServerAuthenticationCertificates,
          },
          permissions: [Permissions.VIEW_SECURITY_SERVER_DETAILS],
        },
      ];
    },
  },
  created() {
    this.fetchDetails();
  },
  methods: {
    ...mapActions(notificationsStore, ['showError']),
    fetchDetails: async function () {
      try {
        await this.securityServerStore.loadById(this.serverId);
      } catch (error: unknown) {
        this.showError(error);
      }
    },
  },
});
