




































import Vue, { PropType } from 'vue';
import { ManagementRequestListView } from '@/openapi-types';
import { Permissions, RouteName } from '@/global';
import { mapState } from 'pinia';
import { userStore } from '@/store/modules/user';

export default Vue.extend({
  props: {
    managementRequest: {
      type: Object as PropType<ManagementRequestListView>,
      required: true,
    },
  },

  computed: {
    ...mapState(userStore, ['hasPermission']),
    canSeeDetails(): boolean {
      return this.hasPermission(Permissions.VIEW_MANAGEMENT_REQUEST_DETAILS);
    },
  },

  methods: {
    navigateToDetails(): void {
      if (!this.canSeeDetails) {
        return;
      }
      this.$router.push({
        name: RouteName.ManagementRequestDetails,
        params: { requestId: String(this.managementRequest.id) },
      });
    },
  },
});
