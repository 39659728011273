

























































































































































import Vue, { VueConstructor } from 'vue';
import { ErrorInfo, MemberClass } from '@/openapi-types';
import { mapStores } from 'pinia';
import { notificationsStore } from '@/store/modules/notifications';
import { useMemberClassStore } from '@/store/modules/member-class';
import { DataTableHeader } from 'vuetify';
import {
  getErrorInfo,
  getTranslatedFieldErrors,
  isFieldError,
} from '@/util/helpers';
import { AxiosError } from 'axios';
import { ValidationProvider } from 'vee-validate';

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        code: InstanceType<typeof ValidationProvider>;
      };
    }
  >
).extend({
  components: { ValidationProvider },
  data: () => ({
    deletingMemberClass: false,
    savingMemberClass: false,
    confirmDeleteDialog: false,
    memberClassDialog: false,
    activeItem: undefined as undefined | MemberClass,
    adding: false,
    valid: true,
  }),
  computed: {
    ...mapStores(useMemberClassStore, notificationsStore),
    memberClasses() {
      return this.memberClassStore.memberClasses;
    },
    fieldRules() {
      return [
        (v: string) =>
          (v.length <= 255 && v.length > 0) || this.$t('validationError.Size'),
      ];
    },
    itemsPerPage() {
      return this.memberClassStore.memberClasses.length > 5 ? 5 : -1;
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('systemSettings.code') as string,
          align: 'start',
          value: 'code',
          class: 'xrd-table-header member-classes-table-header-code',
        },
        {
          text: this.$t('systemSettings.description') as string,
          align: 'start',
          value: 'description',
          class: 'xrd-table-header member-classes-table-header-description',
        },
        {
          text: '',
          value: 'button',
          sortable: false,
          class: 'xrd-table-header member-classes-table-header-buttons',
        },
      ];
    },
  },
  created() {
    this.memberClassStore.fetchAll();
  },
  methods: {
    confirmDelete(item: MemberClass) {
      this.confirmDeleteDialog = true;
      this.activeItem = item;
    },
    async acceptDelete() {
      this.deletingMemberClass = true;
      if (this.activeItem !== undefined) {
        try {
          await this.memberClassStore.delete(this.activeItem);
          this.notificationsStoreStore.showSuccess(
            this.$t('systemSettings.memberClassDeleted'),
          );
        } catch (error: unknown) {
          this.notificationsStoreStore.showError(error);
        }
        this.activeItem = undefined;
      }
      this.confirmDeleteDialog = false;
      this.deletingMemberClass = false;
    },
    cancelDelete() {
      this.confirmDeleteDialog = false;
      this.activeItem = undefined;
    },
    async onSaveMemberClass() {
      if (this.activeItem !== undefined) {
        this.savingMemberClass = true;
        try {
          await (this.adding
            ? this.memberClassStore.add(this.activeItem)
            : this.memberClassStore.update(
                this.activeItem.code,
                this.activeItem.description,
              ));
          this.notificationsStoreStore.showSuccess(
            this.$t('systemSettings.memberClassSaved'),
          );
        } catch (error: unknown) {
          const errorInfo: ErrorInfo = getErrorInfo(error as AxiosError);
          if (isFieldError(errorInfo)) {
            let fieldErrors = errorInfo.error?.validation_errors;
            if (fieldErrors && this.$refs?.code) {
              this.$refs.code.setErrors(
                getTranslatedFieldErrors('memberClassDto.code', fieldErrors),
              );
              return;
            }
          } else {
            this.notificationsStoreStore.showError(error);
          }
        } finally {
          this.savingMemberClass = false;
        }
      }
      this.activeItem = undefined;
      this.memberClassDialog = false;
    },
    openMemberClassDialog(item: MemberClass | undefined) {
      if (item === undefined) {
        this.activeItem = {
          code: '',
          description: '',
        };
        this.adding = true;
      } else {
        this.activeItem = { ...item };
        this.adding = false;
      }
      this.memberClassDialog = true;
    },
  },
});
