












































































import Vue from 'vue';
import InfoCard from '@/components/ui/InfoCard.vue';
import { mapStores } from 'pinia';
import { useIntermediateCaStore } from '@/store/modules/trust-services';

/**
 * Component for a Certification Service details view
 */
export default Vue.extend({
  name: 'IntermediateCaDetails',
  components: {
    InfoCard,
  },
  computed: {
    ...mapStores(useIntermediateCaStore),
  },
});
