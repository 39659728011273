























































































































































/** Base component for simple dialogs */

import Vue from 'vue';
import { mapStores } from 'pinia';
import { useGlobalGroupsStore } from '@/store/modules/global-groups';

const initialState = () => {
  return {
    typeMemberModel: false,
    typeSubsystemModel: false,
    instanceModel: '',
    memberClassModel: '',
    subsystemsModel: [],
    codesModel: [],
  };
};

export default Vue.extend({
  name: 'GroupMembersFilterDialog',
  components: {},
  props: {
    groupCode: {
      type: String,
      required: true,
    },
    // Dialog visible / hidden
    dialog: {
      type: Boolean,
      required: true,
    },
    // Is the content scrollable
    scrollable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 824,
    },
    // Set save button loading spinner
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      instances: [] as string[] | null | undefined,
      memberClasses: [] as string[] | null | undefined,
      subsystems: [] as string[] | null | undefined,
      codes: [] as string[] | null | undefined,
      ...initialState(),
    };
  },

  computed: {
    ...mapStores(useGlobalGroupsStore),
  },
  created() {
    this.globalGroupStore.getMembersFilterModel(this.groupCode).then((resp) => {
      this.instances = resp.instances;
      this.memberClasses = resp.member_classes;
      this.subsystems = resp.subsystems;
      this.codes = resp.codes;
    });
  },
  methods: {
    cancel(): void {
      this.$emit('cancel');
    },
    clearFields(): void {
      Object.assign(this.$data, initialState());
    },
    apply(): void {
      const typeArray: string[] = [];
      if (this.typeMemberModel === true) {
        typeArray.push('MEMBER');
      }
      if (this.typeSubsystemModel === true) {
        typeArray.push('SUBSYSTEM');
      }
      this.$emit('apply', {
        member_class: this.memberClassModel,
        instance: this.instanceModel,
        codes: this.codesModel,
        subsystems: this.subsystemsModel,
        types: typeArray,
      });
    },
  },
});
