



















































































/**
 * Table component for an array of keys
 */
import Vue from 'vue';
import { Prop } from 'vue/types/options';
import { ConfigurationSigningKey, PossibleKeyAction } from '@/openapi-types';
import { DataTableHeader } from 'vuetify';
import { mapState } from 'pinia';
import { userStore } from '@/store/modules/user';
import { Permissions } from '@/global';
import SigningKeyDeleteDialog from '@/components/signingKeys/SigningKeyDeleteDialog.vue';
import SigningKeyActivateDialog from '@/components/signingKeys/SigningKeyActivateDialog.vue';

export default Vue.extend({
  components: { SigningKeyActivateDialog, SigningKeyDeleteDialog },
  props: {
    keys: {
      type: Array as Prop<ConfigurationSigningKey[]>,
      required: true,
    },
    loadingKeys: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedKey: {},
      showDeleteKeyDialog: false,
      showActivateKeyDialog: false,
    };
  },
  computed: {
    ...mapState(userStore, ['hasPermission']),
    canDeleteKeys(): boolean {
      return this.hasPermission(Permissions.DELETE_SIGNING_KEY);
    },
    canActivateKeys(): boolean {
      return this.hasPermission(Permissions.ACTIVATE_SIGNING_KEY);
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('keys.signKey') as string,
          align: 'start',
          value: 'id',
          class: 'xrd-table-header text-uppercase',
        },
        {
          text: this.$t('keys.created') as string,
          align: 'start',
          value: 'createdAt',
          class: 'xrd-table-header text-uppercase',
        },
        {
          text: '',
          align: 'end',
          value: 'actions',
          class: 'xrd-table-header text-uppercase',
        },
      ];
    },
  },
  methods: {
    keyLabel(key: ConfigurationSigningKey): string {
      return key?.label?.label || key.id;
    },
    canDeleteKey(key: ConfigurationSigningKey): boolean {
      return (
        this.canDeleteKeys &&
        key.possible_actions.includes(PossibleKeyAction.DELETE)
      );
    },
    canActivateKey(key: ConfigurationSigningKey): boolean {
      return (
        this.canActivateKeys &&
        key.possible_actions.includes(PossibleKeyAction.ACTIVATE)
      );
    },
    openDeleteKeyDialog(key: ConfigurationSigningKey) {
      this.showDeleteKeyDialog = true;
      this.selectedKey = key;
    },
    openActivateKeyDialog(key: ConfigurationSigningKey) {
      this.showActivateKeyDialog = true;
      this.selectedKey = key;
    },
    updateKeys(action: string) {
      this.closeDialogs();
      this.$emit('update-keys', action);
    },
    closeDialogs() {
      this.showDeleteKeyDialog = false;
      this.showActivateKeyDialog = false;
      this.selectedKey = {};
    },
  },
});
