













































































































































































import {
  Client,
  ManagementServicesConfiguration,
  SecurityServer,
} from '@/openapi-types';
import Vue from 'vue';
import { mapActions, mapState, mapStores } from 'pinia';
import { managementServicesStore } from '@/store/modules/management-services';
import { notificationsStore } from '@/store/modules/notifications';
import { toIdentifier } from '@/util/helpers';
import { Permissions } from '@/global';
import { userStore } from '@/store/modules/user';
import SelectSubsystemDialog from '@/components/systemSettings/SelectSubsystemDialog.vue';
import SelectSecurityServerDialog from '@/components/systemSettings/SelectSecurityServerDialog.vue';

export default Vue.extend({
  components: {
    SelectSubsystemDialog,
    SelectSecurityServerDialog,
  },
  data() {
    return {
      loading: false,
      showSelectSubsystemDialog: false,
      showSelectSecurityServerDialog: false,
    };
  },
  computed: {
    ...mapStores(managementServicesStore),
    ...mapState(userStore, ['hasPermission']),
    managementServicesConfiguration(): ManagementServicesConfiguration {
      return this.managementServicesStore.managementServicesConfiguration;
    },
    hasPermissionToEditServiceProvider(): boolean {
      return this.hasPermission(Permissions.VIEW_SYSTEM_SETTINGS);
    },
    canEditSecurityServer(): boolean {
      return (
        this.isServiceProviderSelected() &&
        this.isServiceProviderUnRegistered() &&
        this.hasPermission(Permissions.REGISTER_SERVICE_PROVIDER)
      );
    },
  },
  created() {
    this.fetchManagementServicesConfiguration();
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    fetchManagementServicesConfiguration(): void {
      this.loading = true;
      this.managementServicesStore
        .fetchManagementServicesConfiguration()
        .finally(() => (this.loading = false));
    },
    copyUrl(url: string): void {
      if (url) {
        navigator.clipboard.writeText(url);
      }
    },
    openSelectSubsystemDialog(): void {
      this.showSelectSubsystemDialog = true;
    },
    hideSelectSubsystemDialog(): void {
      this.showSelectSubsystemDialog = false;
    },
    isServiceProviderSelected(): boolean {
      return (
        this.managementServicesStore.managementServicesConfiguration
          .service_provider_id !== undefined
      );
    },
    isServiceProviderUnRegistered(): boolean {
      return (
        this.managementServicesStore.managementServicesConfiguration
          .security_server_id === ``
      );
    },
    openSelectSecurityServerDialog(): void {
      this.showSelectSecurityServerDialog = true;
    },
    hideSelectSecurityServerDialog(): void {
      this.showSelectSecurityServerDialog = false;
    },
    updateServiceProvider(subsystems: Client[]): void {
      this.loading = true;
      this.managementServicesStore
        .updateManagementServicesConfiguration({
          service_provider_id: toIdentifier(subsystems[0].client_id),
        })
        .then(() => {
          this.showSuccess(
            this.$t('systemSettings.serviceProvider.changedSuccess'),
          );
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.showSelectSubsystemDialog = false;
          this.loading = false;
        });
    },
    registerServiceProvider(securityServers: SecurityServer[]): void {
      this.loading = true;
      this.managementServicesStore
        .registerServiceProvider({
          security_server_id: securityServers[0].server_id.encoded_id || '',
        })
        .then(() => {
          this.showSuccess(
            this.$t('systemSettings.serviceProvider.registeredSuccess', {
              subsystemId:
                this.managementServicesConfiguration.service_provider_id,
              securityServerId:
                this.managementServicesConfiguration.security_server_id,
            }),
          );
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.showSelectSecurityServerDialog = false;
          this.loading = false;
        });
    },
  },
});
