/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * client type
 */
export enum ClientType {
    MEMBER = 'MEMBER',
    SUBSYSTEM = 'SUBSYSTEM',
}