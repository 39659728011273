import { render, staticRenderFns } from "./ManagementRequestsList.vue?vue&type=template&id=459e83f8&scoped=true&"
import script from "./ManagementRequestsList.vue?vue&type=script&lang=ts&"
export * from "./ManagementRequestsList.vue?vue&type=script&lang=ts&"
import style0 from "./ManagementRequestsList.vue?vue&type=style&index=0&id=459e83f8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "459e83f8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCheckbox,VDataTable})
