

























































import { Permissions } from '@/global';
import { notificationsStore } from '@/store/modules/notifications';
import { userStore } from '@/store/modules/user';
import Vue from 'vue';
import { mapActions, mapState, mapStores } from 'pinia';
import { ConfigurationType } from '@/openapi-types';
import { useConfigurationSourceStore } from '@/store/modules/configuration-sources';
import { Prop } from 'vue/types/options';
import { DataTableHeader } from 'vuetify';
import ConfigurationAnchorItem, {
  Anchor,
} from '@/views/GlobalConfiguration/shared/ConfigurationAnchorItem.vue';

export default Vue.extend({
  components: { ConfigurationAnchorItem },
  props: {
    configurationType: {
      type: String as Prop<ConfigurationType>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      downloading: false,
      recreating: false,
    };
  },
  computed: {
    ...mapStores(useConfigurationSourceStore),
    ...mapState(userStore, ['hasPermission']),
    anchor(): Anchor | null {
      const title = this.$t('globalConf.anchor.title').toString();
      const anchor = this.configurationSourceStore.getAnchor(
        this.configurationType,
      );
      return anchor.hash
        ? { hash: anchor.hash, createdAt: anchor.created_at, title }
        : null;
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('globalConf.anchor.certificateHash') as string,
          align: 'start',
          value: 'hash',
          class: 'xrd-table-header text-uppercase',
        },
        {
          text: this.$t('globalConf.anchor.created') as string,
          align: 'start',
          value: 'created_at',
          class: 'xrd-table-header text-uppercase',
        },
      ];
    },
    showDownloadAnchorButton(): boolean {
      return (
        this.hasPermission(Permissions.DOWNLOAD_SOURCE_ANCHOR) &&
        this.configurationSourceStore.hasAnchor(this.configurationType)
      );
    },
    showRecreateAnchorButton(): boolean {
      return this.hasPermission(Permissions.GENERATE_SOURCE_ANCHOR);
    },
    formattedConfigurationType(): string {
      return (
        this.configurationType.charAt(0).toUpperCase() +
        this.configurationType.slice(1).toLowerCase()
      );
    },
  },
  created() {
    this.fetchConfigurationAnchor();
  },
  methods: {
    ...mapActions(notificationsStore, ['showSuccess', 'showError']),
    fetchConfigurationAnchor() {
      this.loading = true;
      this.configurationSourceStore
        .fetchConfigurationAnchor(this.configurationType)
        .catch(this.showError)
        .finally(() => (this.loading = false));
    },
    downloadConfigurationAnchor() {
      this.downloading = true;
      this.configurationSourceStore
        .downloadConfigurationAnchor(this.configurationType)
        .catch(this.showError)
        .finally(() => (this.downloading = false));
    },
    recreateConfigurationAnchor() {
      this.recreating = true;
      this.configurationSourceStore
        .recreateConfigurationAnchor(this.configurationType)
        .then(() =>
          this.showSuccess(
            this.$t(`globalConf.anchor.recreateSuccess`, {
              configurationType: this.formattedConfigurationType,
            }),
          ),
        )
        .catch(this.showError)
        .finally(() => (this.recreating = false));
    },
  },
});
