




















































































































import Vue from 'vue';
import InfoCard from '@/components/ui/InfoCard.vue';
import { Colors, Permissions, RouteName } from '@/global';
import { mapActions, mapState, mapStores } from 'pinia';
import { useSecurityServerStore } from '@/store/modules/security-servers';
import { SecurityServer } from '@/openapi-types';
import { userStore } from '@/store/modules/user';
import EditSecurityServerAddressDialog from '@/views/SecurityServers/SecurityServer/EditSecurityServerAddressDialog.vue';
import DeleteSecurityServerAddressDialog from '@/views/SecurityServers/SecurityServer/DeleteSecurityServerAddressDialog.vue';
import { notificationsStore } from '@/store/modules/notifications';

/**
 * Component for a Security server details view
 */
export default Vue.extend({
  name: 'SecurityServerDetails',
  components: {
    DeleteSecurityServerAddressDialog,
    EditSecurityServerAddressDialog,
    InfoCard,
  },
  props: {
    serverId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      colors: Colors,
    };
  },
  computed: {
    ...mapState(userStore, ['hasPermission']),
    ...mapStores(useSecurityServerStore),
    securityServer(): SecurityServer | null {
      return this.securityServerStore.currentSecurityServer;
    },
    loading(): boolean {
      return this.securityServerStore.currentSecurityServerLoading;
    },
    canEditAddress(): boolean {
      return this.hasPermission(Permissions.EDIT_SECURITY_SERVER_ADDRESS);
    },
    canDeleteServer(): boolean {
      return this.hasPermission(Permissions.DELETE_SECURITY_SERVER);
    },
    address(): string | null {
      return this.securityServer?.server_address || null;
    },
    serverCode(): string | null {
      return this.securityServer?.server_id.server_code || null;
    },
  },
  methods: {
    ...mapActions(notificationsStore, ['showSuccess']),
    deleteServer() {
      this.$router.replace({
        name: RouteName.SecurityServers,
      });
      this.showSuccess(
        this.$t('securityServers.dialogs.deleteAddress.success'),
      );
    },
  },
});
