



















































import Vue, { PropType } from 'vue';
import { ManagementRequestType } from '@/openapi-types';
import { managementTypeToText } from '@/util/helpers';

export default Vue.extend({
  props: {
    type: {
      type: String as PropType<ManagementRequestType>,
      default: undefined,
    },
  },

  computed: {
    typeText() {
      return managementTypeToText(this.type);
    },
  },
});
