





















































import Vue, { PropType } from 'vue';
import { ManagementRequestDetailedView } from '@/openapi-types';
import DataLine from './DetailsLine.vue';
import DataBlock from './DetailsBlock.vue';

export default Vue.extend({
  components: { DataBlock, DataLine },
  props: {
    managementRequest: {
      type: Object as PropType<ManagementRequestDetailedView>,
      required: true,
    },
  },
});
