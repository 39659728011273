










































import Vue from 'vue';
import { mapState } from 'pinia';
import { userStore } from '@/store/modules/user';
import { Permissions } from '@/global';
import DeleteTrustedAnchorDialog from './DeleteTrustedAnchorDialog.vue';

export default Vue.extend({
  components: { DeleteTrustedAnchorDialog },
  props: {
    hash: {
      type: String,
      required: true,
    },
    identifier: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(userStore, ['hasPermission']),
    canDelete(): boolean {
      return this.hasPermission(Permissions.DELETE_TRUSTED_ANCHOR);
    },
  },
});
