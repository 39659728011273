

































import Vue from 'vue';

/**
 * Security servers main view wrapping content all from 'Security servers' main tab
 */
export default Vue.extend({});
