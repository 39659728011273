






























































import Vue from 'vue';
import { ValidationProvider } from 'vee-validate';
import { mapActions } from 'pinia';
import { notificationsStore } from '@/store/modules/notifications';
import { tokenStore } from '@/store/modules/tokens';
import { Prop } from 'vue/types/options';
import { Token } from '@/openapi-types';

export default Vue.extend({
  name: 'TokenLoginDialog',
  components: { ValidationProvider },
  props: {
    token: {
      type: Object as Prop<Token>,
      required: true,
    },
  },
  data() {
    return {
      pin: '',
      loading: false,
    };
  },
  computed: {
    isValid(): boolean {
      return this.pin?.length > 0;
    },
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    ...mapActions(tokenStore, ['loginToken']),
    login(): void {
      this.loading = true;
      this.loginToken(this.token.id, { password: this.pin })
        .then(() => {
          this.showSuccess(this.$t('tokens.loginDialog.success'));
          this.$emit('token-login');
        })
        .catch((error) => {
          const metadata: string[] = error.response?.data?.error?.metadata;
          if (metadata && metadata.length > 0) {
            (
              this.$refs.tokenPin as InstanceType<typeof ValidationProvider>
            ).setErrors(
              metadata.map(
                (code) => this.$t('tokens.errors.' + code) as string,
              ),
            );
          }
          this.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel(): void {
      this.loading = false;
      this.pin = '';
      this.$emit('cancel');
    },
  },
});
