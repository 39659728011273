











































import Vue from 'vue';

export default Vue.extend({
  filters: {
    prettyTitle(value: string) {
      // Replace "snake case" with spaces
      return value.replace(new RegExp('_', 'g'), ' ');
    },

    lineBreaks(value: string) {
      // Add line break after every 60 characters
      return value.replace(/(.{60})/g, '$1\n');
    },
  },
  props: {
    childKey: {
      type: String,
      required: true,
    },
    sourceObject: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    info: {
      type: String,
      required: false,
      default: undefined,
    },
    arrayType: {
      type: Boolean,
      required: false,
    },
    chunk: {
      type: Boolean,
      required: false,
    },
    date: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    formattedData(): string {
      if (this.info) {
        return this.info;
      }

      if (this.arrayType) {
        return this.formatArray(this.sourceObject[this.childKey]);
      }

      if (this.date) {
        // Return readable presentation of date
        const event = new Date(this.sourceObject[this.childKey]);
        return event.toString();
      }

      return this.sourceObject[this.childKey];
    },

    formatArray(arr: []): string {
      const translated: string[] = [];

      arr.forEach((element) => {
        translated.push(this.$t(`cert.keyUsage.${element}`) as string);
      });

      // Return nice looking string representation of an array of strings
      return translated
        .toString()
        .split(',')
        .map((s) => ' ' + s)
        .join(',');
    },
  },
});
