








































import Vue from 'vue';
import PageNavigation, {
  PageNavigationTab,
} from '@/components/layout/PageNavigation.vue';
import { Colors, Permissions, RouteName } from '@/global';
import { mapStores } from 'pinia';
import { memberStore } from '@/store/modules/members';
import DetailsView from '@/components/ui/DetailsView.vue';

/**
 * Wrapper component for a member view
 */
export default Vue.extend({
  name: 'Member',
  components: { DetailsView, PageNavigation },
  props: {
    memberid: {
      type: String,
      required: true,
    },
    backTo: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      colors: Colors,
      savedBackTo: this.backTo,
    };
  },
  computed: {
    ...mapStores(memberStore),
    memberNavigationTabs(): PageNavigationTab[] {
      return [
        {
          key: 'member-details-tab-button',
          name: 'members.member.pagenavigation.details',
          to: {
            name: RouteName.MemberDetails,
          },
          permissions: [Permissions.VIEW_MEMBER_DETAILS],
        },

        {
          key: 'member-subsystems-tab-button',
          name: 'members.member.pagenavigation.subsystems',
          to: {
            name: RouteName.MemberSubsystems,
          },
          permissions: [Permissions.VIEW_MEMBER_DETAILS],
        },
      ];
    },
  },
  created() {
    this.memberStore.loadById(this.memberid);
  },
});
