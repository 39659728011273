








































































































import Vue from 'vue';
import { FileUploadResult } from '@niis/shared-ui';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { TimestampingService } from '@/openapi-types';
import { RouteName } from '@/global';
import { mapActions, mapStores } from 'pinia';
import { timestampingServicesStore } from '@/store/modules/trust-services';
import { notificationsStore } from '@/store/modules/notifications';

export default Vue.extend({
  name: 'EditTimestampingServiceDialog',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    tsaService: {
      type: Object as () => TimestampingService,
      required: true,
    },
  },
  data() {
    return {
      certFile: null as File | null,
      certFileTitle: '',
      certUploadActive: false,
      tasUrl: this.tsaService?.url,
      loading: false,
    };
  },
  computed: {
    ...mapStores(timestampingServicesStore),
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    onFileUploaded(result: FileUploadResult): void {
      this.certFile = result.file;
      this.certFileTitle = result.file.name;
    },
    update(): void {
      this.loading = true;

      this.timestampingServicesStore
        .updateTimestampingService(
          this.tsaService.id,
          this.tasUrl,
          this.certFile,
        )
        .then(() => {
          this.showSuccess(
            this.$t('trustServices.timestampingService.dialog.edit.success'),
          );
          this.$emit('save');
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => (this.loading = false));
    },
    navigateToTsaDetails() {
      this.$router.push({
        name: RouteName.TimestampingServiceCertificateDetails,
        params: {
          timestampingServiceId: '' + this.tsaService.id,
        },
      });
    },
    cancel(): void {
      this.$emit('cancel');
    },
  },
});
