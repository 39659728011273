





















































































































import Vue, { VueConstructor } from 'vue';
import { mapActions, mapState } from 'pinia';
import { userStore } from '@/store/modules/user';
import { notificationsStore } from '@/store/modules/notifications';
import { systemStore } from '@/store/modules/system';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { RouteName } from '@/global';
import AlertsContainer from '@/components/ui/AlertsContainer.vue';
import { swallowRedirectedNavigationError } from '@/util/helpers';
import axios from 'axios';

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        form: InstanceType<typeof ValidationObserver>;
      };
    }
  >
).extend({
  name: 'Login',
  components: {
    ValidationProvider,
    ValidationObserver,
    AlertsContainer,
  },
  data() {
    return {
      loading: false as boolean,
      username: '' as string,
      password: '' as string,
    };
  },
  computed: {
    ...mapState(userStore, ['getFirstAllowedTab']),
    isDisabled() {
      // beware: simplified one-liner fails at runtime
      if (
        this.username.length < 1 ||
        this.password.length < 1 ||
        this.loading
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions(userStore, ['login', 'fetchUserData']),
    ...mapActions(notificationsStore, [
      'showError',
      'showErrorMessage',
      'resetNotifications',
    ]),
    ...mapActions(systemStore, ['fetchSystemStatus', 'fetchServerVersion']),
    async submit() {
      // Clear old error notifications (if they exist) before submit
      await this.resetNotifications();

      // Validate inputs
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      const loginData = {
        username: this.username,
        password: this.password,
      };

      this.$refs.form.reset();
      this.loading = true;

      try {
        await this.login(loginData);
      } catch (error: unknown) {
        // Display invalid username/password error in inputs
        if (axios.isAxiosError(error) && error?.response?.status === 401) {
          // Clear inputs
          this.username = '';
          this.password = '';
          this.$refs.form.reset();
          // The whole view needs to be rendered so the "required" rule doesn't block
          // "wrong unsername or password" error in inputs
          this.$nextTick(() => {
            // Set inputs to error state
            this.$refs.form.setErrors({
              username: [''],
              password: [this.$t('login.errorMsg401') as string],
            });
          });
          this.showErrorMessage(this.$t('login.generalError'));
        } else {
          this.showError(error);
        }
        this.loading = false;
        return;
      }
      try {
        await this.requestUserData();
        await this.fetchServerVersion();
        await this.fetchSystemStatus();
        await this.routeToMembersPage();
      } catch (error) {
        this.showError(error);
        this.loading = false;
      }
    },
    async requestUserData() {
      this.$refs.form.reset();
      this.loading = true;
      return this.fetchUserData();
    },
    async routeToMembersPage() {
      this.$router
        .replace(this.getFirstAllowedTab.to)
        .catch(swallowRedirectedNavigationError);
      this.loading = false;
    },
  },
});
