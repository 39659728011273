


































































import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import { Prop } from 'vue/types/options';

export interface Anchor {
  title: string;
  hash: string;
  createdAt?: string;
}

export default Vue.extend({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    anchor: {
      type: Object as Prop<Anchor>,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    title(): string {
      return this.anchor ? this.anchor.title : '';
    },
    anchors(): Anchor[] {
      return this.anchor ? [this.anchor] : [];
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('globalConf.anchor.certificateHash') as string,
          align: 'start',
          value: 'hash',
          class: 'xrd-table-header text-uppercase',
        },
        {
          text: this.$t('globalConf.anchor.created') as string,
          align: 'start',
          value: 'createdAt',
          class: 'xrd-table-header text-uppercase',
        },
      ];
    },
  },

  methods: {},
});
