/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * X-Road identifier
 */
export type XRoadId = {
    /**
     * Xroad instance id. Cannot contain colons, semicolons, slashes, backslashes, percent signs or control characters
     */
    readonly instance_id: string;
    type: XRoadId.type;
}

export namespace XRoadId {

    export enum type {
        MEMBER = 'MEMBER',
        SUBSYSTEM = 'SUBSYSTEM',
        SERVER = 'SERVER',
        GLOBALGROUP = 'GLOBALGROUP',
        SERVICE = 'SERVICE',
        LOCALGROUP = 'LOCALGROUP',
    }


}
