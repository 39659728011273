













































































































































import Vue from 'vue';

import { Permissions } from '@/global';
import { DataOptions, DataTableHeader } from 'vuetify';
import { useGlobalGroupsStore } from '@/store/modules/global-groups';
import { mapActions, mapState, mapStores } from 'pinia';
import { GroupMembersFilter } from '@/openapi-types';
import { notificationsStore } from '@/store/modules/notifications';
import { userStore } from '@/store/modules/user';
import GroupMembersFilterDialog from './GroupMembersFilterDialog.vue';
import { debounce } from '@/util/helpers';
import AddGroupMembersDialog from './AddGroupMembersDialog.vue';
import DeleteGroupMemberDialog from './DeleteGroupMemberDialog.vue';

// To provide the Vue instance to debounce
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let that: any;

export default Vue.extend({
  components: {
    DeleteGroupMemberDialog,
    AddGroupMembersDialog,
    GroupMembersFilterDialog,
  },
  props: {
    groupCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pagingSortingOptions: {} as DataOptions,
      filter: {} as GroupMembersFilter,
      loading: false,
      showFilterDialog: false,
    };
  },
  computed: {
    ...mapStores(useGlobalGroupsStore),
    ...mapState(userStore, ['hasPermission']),
    allowAddAndRemoveGroupMembers(): boolean {
      return this.hasPermission(Permissions.ADD_AND_REMOVE_GROUP_MEMBERS);
    },
    memberCount(): number {
      return this.globalGroupStore.pagingOptions === undefined
        ? 0
        : this.globalGroupStore.pagingOptions.total_items;
    },
    membersHeaders(): DataTableHeader[] {
      return [
        {
          text: this.$t('globalGroup.memberName') as string,
          align: 'start',
          value: 'name',
          class: 'xrd-table-header gp-table-header-member-name',
        },
        {
          text: this.$t('globalGroup.type') as string,
          align: 'start',
          value: 'type',
          class: 'xrd-table-header gp-table-header-member-type',
        },
        {
          text: this.$t('globalGroup.instance') as string,
          align: 'start',
          value: 'instance',
          class: 'xrd-table-header gp-table-header-member-instance',
        },
        {
          text: this.$t('globalGroup.class') as string,
          align: 'start',
          value: 'class',
          class: 'xrd-table-header gp-table-header-member-class',
        },
        {
          text: this.$t('globalGroup.code') as string,
          align: 'start',
          value: 'code',
          class: 'xrd-table-header gp-table-header-member-code',
        },
        {
          text: this.$t('globalGroup.subsystem') as string,
          align: 'start',
          value: 'subsystem',
          class: 'xrd-table-header gp-table-header-member-subsystem',
        },
        {
          text: this.$t('globalGroup.added') as string,
          align: 'start',
          value: 'created_at',
          class: 'xrd-table-header gp-table-header-member-created',
        },
        {
          value: 'button',
          text: '',
          sortable: false,
          class: 'xrd-table-header groups-table-header-buttons',
        },
      ];
    },
  },
  watch: {
    filter: {
      handler() {
        this.pagingSortingOptions.page = 1;
        this.debouncedFetchItems();
      },
      deep: true,
    },
  },
  created() {
    that = this;
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    debouncedFetchItems: debounce(() => {
      // Debounce is used to reduce unnecessary api calls
      that.fetchItems(that.pagingSortingOptions, that.filter);
    }, 600),
    changeOptions: async function () {
      await this.fetchItems(this.pagingSortingOptions, this.filter);
    },
    fetchItems: async function (
      options: DataOptions,
      filter: GroupMembersFilter,
    ) {
      this.loading = true;
      try {
        await this.globalGroupStore.findMembers(
          this.groupCode,
          options,
          filter,
        );
      } catch (error: unknown) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    cancelFilter(): void {
      this.showFilterDialog = false;
    },
    applyFilter(filter: GroupMembersFilter): void {
      this.filter.query = '';
      this.fetchItems(this.pagingSortingOptions, filter);
      this.showFilterDialog = false;
    },
    refreshList() {
      this.fetchItems(this.pagingSortingOptions, this.filter);
    },
  },
});
