







































import Vue from 'vue';
import { mapActions } from 'pinia';
import { notificationsStore } from '@/store/modules/notifications';
import { tokenStore } from '@/store/modules/tokens';
import { Token } from '@/openapi-types';
import { Prop } from 'vue/types/options';

export default Vue.extend({
  props: {
    token: {
      type: Object as Prop<Token>,
      required: true,
    },
  },
  data() {
    return {
      showConfirmLogout: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    ...mapActions(tokenStore, ['logoutToken']),
    cancel(): void {
      this.$emit('cancel');
    },
    confirmLogout(): void {
      this.loading = true;
      this.logoutToken(this.token.id)
        .then(() => {
          this.showSuccess(this.$t('tokens.logout.success'));
          this.$emit('token-logout');
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
