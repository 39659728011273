
























































































































import Vue from 'vue';
import { Client, PagedClients } from '@/openapi-types';
import { mapActions, mapStores } from 'pinia';
import { clientStore } from '@/store/modules/clients';
import { notificationsStore } from '@/store/modules/notifications';
import { debounce, toIdentifier } from '@/util/helpers';
import { DataOptions, DataTableHeader } from 'vuetify';

// To provide the Vue instance to debounce
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let that: any;

export default Vue.extend({
  name: 'SelectSubsystemDialog',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    defaultSubsystemId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      pagingSortingOptions: {} as DataOptions,
      clients: {} as PagedClients,
      search: '',
      selectedSubsystems: [] as Client[],
    };
  },
  computed: {
    ...mapStores(clientStore),
    totalItems(): number {
      return this.clients.paging_metadata?.total_items || 0;
    },
    selectableSubsystems(): Client[] {
      return this.clients.clients || [];
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('systemSettings.selectSubsystem.name') as string,
          align: 'start',
          value: 'member_name',
          class: 'xrd-table-header text-uppercase',
        },
        {
          text: this.$t('systemSettings.selectSubsystem.memberCode') as string,
          align: 'start',
          value: 'client_id.member_code',
          class: 'xrd-table-header text-uppercase',
        },
        {
          text: this.$t('systemSettings.selectSubsystem.memberClass') as string,
          align: 'start',
          value: 'client_id.member_class',
          class: 'xrd-table-header text-uppercase',
        },
        {
          text: this.$t(
            'systemSettings.selectSubsystem.subsystemCode',
          ) as string,
          align: 'start',
          value: 'client_id.subsystem_code',
          class: 'xrd-table-header text-uppercase',
        },
        {
          text: this.$t(
            'systemSettings.selectSubsystem.xroadInstance',
          ) as string,
          align: 'start',
          value: 'client_id.instance_id',
          class: 'xrd-table-header text-uppercase',
        },
        {
          text: this.$t('systemSettings.selectSubsystem.type') as string,
          align: 'start',
          value: 'client_id.type',
          class: 'xrd-table-header text-uppercase',
        },
      ];
    },
  },
  watch: {
    search: {
      handler() {
        this.pagingSortingOptions.page = 1;
        this.debouncedFetchItems();
      },
      deep: true,
    },
  },
  created() {
    that = this;
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    debouncedFetchItems: debounce(() => {
      // Debounce is used to reduce unnecessary api calls
      that.fetchClients();
    }, 600),
    fetchClients() {
      this.loading = true;
      this.clientStore
        .getByClientType('SUBSYSTEM', this.search, this.pagingSortingOptions)
        .then((resp) => {
          this.clients = resp;
          this.setSelectedSubsystems();
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeOptions: async function () {
      this.fetchClients();
    },
    setSelectedSubsystems() {
      const filteredList = this.selectableSubsystems?.filter(
        (subsystem) =>
          `SUBSYSTEM:${toIdentifier(subsystem.client_id)}` ===
          this.defaultSubsystemId,
      );

      if (filteredList) {
        this.selectedSubsystems = filteredList;
      }
    },
    cancel(): void {
      this.$emit('cancel');
      this.clearForm();
    },
    selectSubSystem(): void {
      this.$emit('select', this.selectedSubsystems);
      this.clearForm();
    },
    clearForm(): void {
      this.pagingSortingOptions.page = 1;
      this.selectedSubsystems = [];
      this.search = '';
    },
  },
});
