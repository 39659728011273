











































import Vue from 'vue';
import { Colors } from '@/global';

export default Vue.extend({
  data() {
    return {
      colors: Colors,
    };
  },
});
