















































import Vue from 'vue';
import { mapActions, mapStores } from 'pinia';
import { useCertificationServiceStore } from '@/store/modules/trust-services';
import { ApprovedCertificationService } from '@/openapi-types';
import { notificationsStore } from '@/store/modules/notifications';

export default Vue.extend({
  name: 'EditTlsAuthDialog',
  props: {
    certificationService: {
      type: Object as () => ApprovedCertificationService,
      required: true,
    },
  },
  data() {
    return {
      tlsAuth: this.certificationService.tls_auth,
      loading: false,
    };
  },
  computed: {
    ...mapStores(useCertificationServiceStore),
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    cancelEdit(): void {
      this.$emit('cancel');
    },
    updateCertificationServiceSettings(): void {
      this.loading = true;
      this.certificationServiceStore
        .update(this.certificationService.id, {
          certificate_profile_info:
            this.certificationService.certificate_profile_info,
          tls_auth: `${this.tlsAuth}`,
        })
        .then(() => {
          this.showSuccess(
            this.$t('trustServices.trustService.settings.saveSuccess'),
          );
          this.$emit('tlsAuthChanged');
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => (this.loading = false));
    },
  },
});
