


















































import Vue from 'vue';
import PageNavigation, {
  PageNavigationTab,
} from '@/components/layout/PageNavigation.vue';
import { Colors, Permissions, RouteName } from '@/global';
import { mapStores } from 'pinia';
import { useIntermediateCaStore } from '@/store/modules/trust-services';

/**
 * Wrapper component for intermediate CA view
 */
export default Vue.extend({
  name: 'IntermediateCa',
  components: { PageNavigation },
  props: {
    intermediateCaId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      colors: Colors,
    };
  },
  computed: {
    ...mapStores(useIntermediateCaStore),
    intermediateCaNavigationTabs(): PageNavigationTab[] {
      return [
        {
          key: 'intermediate-ca-details-tab-button',
          name: 'trustServices.trustService.pagenavigation.details',
          to: {
            name: RouteName.IntermediateCaDetails,
          },
          permissions: [Permissions.VIEW_APPROVED_CA_DETAILS],
        },

        {
          key: 'intermediate-ca-ocsp-responders-tab-button',
          name: 'trustServices.trustService.pagenavigation.ocspResponders',
          to: {
            name: RouteName.IntermediateCaOcspResponders,
          },
          permissions: [Permissions.VIEW_APPROVED_CA_DETAILS],
        },
      ];
    },
  },
  created() {
    this.intermediateCasServiceStore.loadById(this.intermediateCaId);
  },
  methods: {
    navigateToCertificateDetails() {
      this.$router.push({
        name: RouteName.IntermediateCACertificateDetails,
        params: {
          intermediateCaId: String(this.intermediateCaId),
        },
      });
    },
  },
});
