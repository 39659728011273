













































































import Vue, {VueConstructor} from 'vue';
import {mapActions, mapState, mapStores} from 'pinia';
import {ErrorInfo, MemberClass} from '@/openapi-types';
import {clientStore} from '@/store/modules/clients';
import {memberStore} from '@/store/modules/members';
import {systemStore} from '@/store/modules/system';
import {notificationsStore} from '@/store/modules/notifications';
import {useMemberClassStore} from '@/store/modules/member-class';
import {getErrorInfo, getTranslatedFieldErrors, isFieldError,} from '@/util/helpers';
import {AxiosError} from 'axios';
import {ValidationProvider} from 'vee-validate';

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        memberCodeVP: InstanceType<typeof ValidationProvider>;
      };
    }
  >
).extend({
  name: 'AddMemberDialog',
  components: { ValidationProvider },
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      memberName: '',
      memberClass: '',
      memberCode: '',
    };
  },
  computed: {
    ...mapStores(clientStore, memberStore, useMemberClassStore),
    ...mapState(systemStore, ['getSystemStatus']),
    memberClasses(): MemberClass[] {
      return this.memberClassStore.memberClasses;
    },
    formReady(): boolean {
      return !!(this.memberName && this.memberClass && this.memberCode);
    },
  },
  created() {
    this.memberClassStore.fetchAll();
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    cancel(): void {
      this.$emit('cancel');
      this.clearForm();
    },
    clearForm(): void {
      this.memberName = '';
      this.memberClass = '';
      this.memberCode = '';
    },
    add(): void {
      this.loading = true;
      const instanceId: string = this.getSystemStatus?.initialization_status
        ?.instance_identifier as string;
      this.memberStore
        .add({
          member_name: this.memberName,
          member_id: {
            member_class: this.memberClass,
            member_code: this.memberCode,
          },
        })
        .then(() => {
          this.showSuccess(
            this.$t('members.memberSuccessfullyAdded', {
              memberName: this.memberName,
            }),
          );
          this.$emit('save');
          this.clearForm();
        })
        .catch((error) => {
          const errorInfo: ErrorInfo = getErrorInfo(error as AxiosError);
          if (isFieldError(errorInfo)) {
            let fieldErrors = errorInfo.error?.validation_errors;
            if (fieldErrors && this.$refs?.memberCodeVP) {
              this.$refs.memberCodeVP.setErrors(
                getTranslatedFieldErrors(
                  'memberAddDto.memberId.memberCode',
                  fieldErrors,
                ),
              );
            }
          } else {
            this.showError(error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
