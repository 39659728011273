/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * timestamping service cost (free, paid, undefined)
 */
export enum TimestampingServiceCost {
    FREE = 'FREE',
    PAID = 'PAID',
    UNDEFINED = 'UNDEFINED',
}