



































































import Vue, { PropType } from 'vue';
import { ManagementRequestListView } from '@/openapi-types';
import { mapState } from 'pinia';
import { userStore } from '@/store/modules/user';
import { Permissions } from '@/global';
import MrConfirmDialog from '@/components/managementRequests/MrConfirmDialog.vue';
import MrDeclineDialog from '@/components/managementRequests/MrDeclineDialog.vue';

/**
 * General component for Management request actions
 */
export default Vue.extend({
  components: {
    MrDeclineDialog,
    MrConfirmDialog,
  },
  props: {
    managementRequest: {
      type: Object as PropType<ManagementRequestListView>,
      required: true,
    },
  },
  data() {
    return {
      showApproveDialog: false,
    };
  },
  computed: {
    ...mapState(userStore, ['hasPermission']),
    showApproveButton(): boolean {
      return this.hasPermission(Permissions.VIEW_MANAGEMENT_REQUEST_DETAILS);
    },
    showDeclineButton(): boolean {
      return this.hasPermission(Permissions.VIEW_MANAGEMENT_REQUEST_DETAILS);
    },
  },

  methods: {},
});
