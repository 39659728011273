













































































import Vue from 'vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapActions, mapStores } from 'pinia';
import { useSecurityServerStore } from '@/store/modules/security-servers';
import { notificationsStore } from '@/store/modules/notifications';

/**
 * Component for a Security server details view
 */
export default Vue.extend({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    securityServerId: {
      type: String,
      default: '',
    },
    serverCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      offeredCode: '',
    };
  },
  computed: {
    ...mapStores(useSecurityServerStore),
  },
  methods: {
    ...mapActions(notificationsStore, ['showError']),
    open(): void {
      this.showDialog = true;
    },
    close(): void {
      this.offeredCode = '';
      this.showDialog = false;
    },
    deleteSecurityServer: async function () {
      try {
        this.loading = true;
        await this.securityServerStore.delete(this.securityServerId);
        this.close();
        this.$emit('deleted');
      } catch (error: unknown) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
