
































import Vue from 'vue';
import CertificateDetails from '@/components/certificate/CertificateDetails.vue';
import { CertificateDetails as CertificateDetailsType } from '@/openapi-types';
import { mapStores } from 'pinia';
import { timestampingServicesStore } from '@/store/modules/trust-services';

export default Vue.extend({
  name: 'TimestampingServiceCertificate',
  components: { CertificateDetails },
  props: {
    timestampingServiceId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      certificateDetails: null as CertificateDetailsType | null,
    };
  },
  computed: {
    ...mapStores(timestampingServicesStore),
  },
  created() {
    window.scrollTo(0, 0);
    const find = this.timestampingServicesStore.timestampingServices.find(
      (tsa) => tsa.id === this.timestampingServiceId,
    );

    if (find) {
      this.certificateDetails = find.certificate;
    }
  },
});
