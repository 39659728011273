





































import Vue from 'vue';

export default Vue.extend({
  props: {
    labelTextKey: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: false,
      default: '',
    },
  },
});
