








































import Vue from 'vue';
import { mapActions, mapState, mapStores } from 'pinia';
import { userStore } from '@/store/modules/user';
import { Permissions } from '@/global';
import { trustedAnchorStore } from '@/store/modules/trusted-anchors';
import { notificationsStore } from '@/store/modules/notifications';

export default Vue.extend({
  props: {
    hash: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(userStore, ['hasPermission']),
    ...mapStores(trustedAnchorStore),
    canDownload(): boolean {
      return this.hasPermission(Permissions.DOWNLOAD_TRUSTED_ANCHOR);
    },
  },
  methods: {
    ...mapActions(notificationsStore, ['showError']),
    download() {
      this.loading = true;
      this.trustedAnchorStore
        .downloadTrustedAnchor(this.hash)
        .catch((error) => this.showError(error))
        .finally(() => (this.loading = false));
    },
  },
});
