






































































/**
 * View for 'security servers' tab
 */
import Vue from 'vue';
import { DataOptions, DataTableHeader } from 'vuetify';
import { RouteName } from '@/global';
import { SecurityServer } from '@/openapi-types';
import { useSecurityServerStore } from '@/store/modules/security-servers';
import { mapActions, mapStores } from 'pinia';
import { notificationsStore } from '@/store/modules/notifications';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;
import { debounce } from '@/util/helpers';

// To provide the Vue instance to debounce
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let that: any;

export default Vue.extend({
  data() {
    return {
      search: '',
      loading: false,
      showOnlyPending: false,
      pagingSortingOptions: {} as DataOptions,
    };
  },
  computed: {
    ...mapStores(useSecurityServerStore),
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('securityServers.serverCode') as string,
          align: 'start',
          value: 'server_id.server_code',
          class: 'xrd-table-header ss-table-header-sercer-code',
        },
        {
          text: this.$t('securityServers.ownerName') as string,
          align: 'start',
          value: 'owner_name',
          class: 'xrd-table-header ss-table-header-owner-name',
        },
        {
          text: this.$t('securityServers.ownerCode') as string,
          align: 'start',
          value: 'server_id.member_code',
          class: 'xrd-table-header ss-table-header-owner-code',
        },
        {
          text: this.$t('securityServers.ownerClass') as string,
          align: 'start',
          value: 'server_id.member_class',
          class: 'xrd-table-header ss-table-header-owner-class',
        },
      ];
    },
    emptyListReasoning(): TranslateResult {
      return this.search
        ? this.$t('noData.noMatches')
        : this.$t('noData.noSecurityServers');
    },
  },

  watch: {
    search: function () {
      this.debouncedFindServers();
    },
  },
  created() {
    that = this;
  },
  methods: {
    ...mapActions(notificationsStore, ['showError']),
    debouncedFindServers: debounce(() => {
      // Debounce is used to reduce unnecessary api calls
      that.findServers(that.pagingSortingOptions);
    }, 600),
    toDetails(securityServer: SecurityServer): void {
      this.$router.push({
        name: RouteName.SecurityServerDetails,
        params: { serverId: securityServer.server_id.encoded_id || '' },
      });
    },
    changeOptions: async function () {
      this.findServers(this.pagingSortingOptions);
    },
    findServers: async function (options: DataOptions) {
      this.loading = true;

      try {
        await this.securityServerStore.find(options, this.search);
      } catch (error: unknown) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
