


















































import Vue from 'vue';
import { mapState } from 'pinia';
import { ConfigurationPart, ConfigurationType } from '@/openapi-types';
import { Prop } from 'vue/types/options';
import { userStore } from '@/store/modules/user';
import { Permissions } from '@/global';
import UploadConfigurationPartDialog from '@/components/configurationParts/UploadConfigurationPartDialog.vue';

export default Vue.extend({
  components: { UploadConfigurationPartDialog },
  props: {
    configurationType: {
      type: String as Prop<ConfigurationType>,
      required: true,
    },
    configurationPart: {
      type: Object as Prop<ConfigurationPart>,
      required: true,
    },
  },
  data() {
    return {
      showUploadDialog: false,
    };
  },
  computed: {
    ...mapState(userStore, ['hasPermission']),

    showUploadButton(): boolean {
      return (
        this.hasPermission(Permissions.UPLOAD_CONFIGURATION_PART) &&
        this.configurationPart.optional
      );
    },
  },
  methods: {},
});
