





























































/**
 * View for 'backup and restore' tab
 */
import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import ConfigurationAnchorItem, {
  Anchor,
} from '@/views/GlobalConfiguration/shared/ConfigurationAnchorItem.vue';
import { TrustedAnchor } from '@/openapi-types';
import { mapActions, mapState, mapStores } from 'pinia';
import { userStore } from '@/store/modules/user';
import { trustedAnchorStore } from '@/store/modules/trusted-anchors';
import { notificationsStore } from '@/store/modules/notifications';
import UploadTrustedAnchorButton from '@/components/trustedAnchors/UploadTrustedAnchorButton.vue';
import DownloadTrustedAnchorButton from '@/components/trustedAnchors/DownloadTrustedAnchorButton.vue';
import DeleteTrustedAnchorButton from '@/components/trustedAnchors/DeleteTrustedAnchorButton.vue';

function convert(source: TrustedAnchor): Anchor {
  return {
    hash: source.hash,
    createdAt: source.generated_at,
    title: source.instance_identifier,
  };
}

export default Vue.extend({
  components: {
    DeleteTrustedAnchorButton,
    DownloadTrustedAnchorButton,
    UploadTrustedAnchorButton,
    ConfigurationAnchorItem,
  },
  data() {
    return {
      loading: false,
      trustedAnchors: [] as Anchor[],
    };
  },
  computed: {
    ...mapStores(trustedAnchorStore),
    ...mapState(userStore, ['hasPermission']),
    headers(): DataTableHeader[] {
      return [
        {
          text: 'Certificate HASH (SHA-224)',
          align: 'start',
          value: 'hash',
          class: 'xrd-table-header tra-table-header-hash',
        },
        {
          text: this.$t('global.created') as string,
          align: 'start',
          value: 'created',
          class: 'xrd-table-header tra-table-header-created',
        },

        {
          text: '',
          value: 'button',
          sortable: false,
          class: 'xrd-table-header tra-table-header-buttons',
        },
      ];
    },
  },
  created() {
    this.fetchTrustedAnchors();
  },
  methods: {
    ...mapActions(notificationsStore, ['showSuccess', 'showError']),
    fetchTrustedAnchors() {
      this.loading = true;
      this.trustedAnchorStore
        .fetchTrustedAnchors()
        .then((resp) => (this.trustedAnchors = resp.data.map(convert)))
        .catch((error) => this.showError(error))
        .finally(() => (this.loading = false));
    },
  },
});
