

















































import Vue from 'vue';

export default Vue.extend({
  name: 'InfoCard',
  props: {
    // Text for the title
    titleText: {
      type: String,
      required: true,
    },
    // Information text
    infoText: {
      type: String,
      required: true,
    },
    // Action in the right end
    actionText: {
      type: String,
      required: false,
      default: undefined,
    },
    showAction: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    emitActionClick(): void {
      this.$emit('actionClicked');
    },
  },
});
