








































// The root component of the Vue app
import Vue from 'vue';
import Snackbar from '@/components/ui/Snackbar.vue';
import AppFooter from '@/components/layout/AppFooter.vue';
import AppToolbar from '@/components/layout/AppToolbar.vue';
import { RouteName } from '@/global';

export default Vue.extend({
  name: 'App',

  components: {
    AppFooter,
    AppToolbar,
    Snackbar,
  },
  computed: {
    loginView(): boolean {
      return this.$route.name !== RouteName.Login;
    },
  },
});
