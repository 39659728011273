/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * request status
 */
export enum ManagementRequestStatus {
    WAITING = 'WAITING',
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
    REVOKED = 'REVOKED',
    SUBMITTED_FOR_APPROVAL = 'SUBMITTED FOR APPROVAL',
}