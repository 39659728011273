




























































import Vue from 'vue';
import { FileUploadResult } from '@niis/shared-ui';
import { mapActions, mapStores } from 'pinia';
import { notificationsStore } from '@/store/modules/notifications';
import { useConfigurationSourceStore } from '@/store/modules/configuration-sources';
import { Prop } from 'vue/types/options';
import {
  ConfigurationPartContentIdentifier,
  ConfigurationType,
} from '@/openapi-types';

export default Vue.extend({
  name: 'UploadConfigurationPartDialog',
  props: {
    configurationType: {
      type: String as Prop<ConfigurationType>,
      required: true,
    },
    contentIdentifier: {
      type: String as Prop<ConfigurationPartContentIdentifier>,
      required: true,
    },
  },
  data() {
    return {
      partFile: null as File | null,
      partFileTitle: '',
      loading: false,
    };
  },
  computed: {
    ...mapStores(useConfigurationSourceStore),
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    onFileUploaded(result: FileUploadResult): void {
      this.partFile = result.file;
      this.partFileTitle = result.file.name;
    },
    save(): void {
      if (!this.partFile) return;

      this.loading = true;
      this.configurationSourceStore
        .uploadConfigurationFile(
          this.configurationType,
          this.contentIdentifier,
          this.partFile,
        )
        .then(() => {
          this.showSuccess(
            this.$t('globalConf.cfgParts.dialog.upload.success'),
          );
          this.$emit('save');
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => (this.loading = false));
    },
  },
});
