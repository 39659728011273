



























































































































































import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import { Colors, Permissions, RouteName } from '@/global';
import InfoCard from '@/components/ui/InfoCard.vue';
import { mapActions, mapState, mapStores } from 'pinia';
import { memberStore } from '@/store/modules/members';
import { MemberGlobalGroup, SecurityServer } from '@/openapi-types';
import { notificationsStore } from '@/store/modules/notifications';
import { userStore } from '@/store/modules/user';
import MemberDeleteDialog from '@/views/Members/Member/Details/DeleteMemberDialog.vue';
import EditMemberNameDialog from '@/views/Members/Member/Details/EditMemberNameDialog.vue';

// To provide the Vue instance to debounce
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let that: any;

/**
 * Component for a Members details view
 */
export default Vue.extend({
  name: 'MemberDetails',
  components: {
    EditMemberNameDialog,
    MemberDeleteDialog,
    InfoCard,
  },
  props: {
    memberid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      colors: Colors,

      showEditNameDialog: false,
      showDeleteDialog: false,

      loadingServers: false,
      searchServers: '',
      servers: [] as SecurityServer[],

      loadingGroups: false,
      searchGroups: '',
      globalGroups: [] as MemberGlobalGroup[],
    };
  },
  computed: {
    ...mapState(userStore, ['hasPermission']),
    ...mapStores(memberStore),
    allowMemberDelete(): boolean {
      return this.hasPermission(Permissions.DELETE_MEMBER);
    },
    allowMemberRename(): boolean {
      return this.hasPermission(Permissions.EDIT_MEMBER_NAME);
    },
    serversHeaders(): DataTableHeader[] {
      return [
        {
          text: this.$t('global.server') as string,
          align: 'start',
          value: 'server_id.server_code',
          class: 'xrd-table-header servers-table-header-server',
        },
      ];
    },
    groupsHeaders(): DataTableHeader[] {
      return [
        {
          value: 'group_code',
          text: this.$t('members.member.details.group') as string,
          align: 'start',
          class: 'xrd-table-header groups-table-header-group',
        },
        {
          value: 'subsystem',
          text: this.$t('global.subsystem') as string,
          align: 'start',
          class: 'xrd-table-header groups-table-header-subsystem',
        },
        {
          value: 'added_to_group',
          text: this.$t('members.member.details.addedToGroup') as string,
          align: 'start',
          class: 'xrd-table-header groups-table-header-added',
        },
      ];
    },
  },
  created() {
    that = this;

    this.loadingGroups = true;
    this.memberStore
      .getMemberGlobalGroups(this.memberid)
      .then((resp) => {
        this.globalGroups = resp;
      })
      .catch((error) => {
        this.showError(error);
      })
      .finally(() => {
        this.loadingGroups = false;
      });

    this.loadingServers = true;
    this.memberStore
      .getMemberOwnedServers(this.memberid)
      .then((resp) => {
        this.servers = resp;
      })
      .catch((error) => {
        this.showError(error);
      })
      .finally(() => {
        this.loadingServers = false;
      });
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    cancelEditMemberName() {
      this.showEditNameDialog = false;
    },
    memberNameChanged() {
      this.showEditNameDialog = false;
    },
    memberDeleted() {
      this.showDeleteDialog = false;
      this.$router.replace({ name: RouteName.Members });
      this.showSuccess(this.$t('members.member.details.memberDeleted'));
    },
    cancelDelete() {
      this.showDeleteDialog = false;
    },
  },
});
