







































import Vue from 'vue';
import { mapActions, mapStores } from 'pinia';
import { managementRequestsStore } from '@/store/modules/managementRequestStore';
import { notificationsStore } from '@/store/modules/notifications';

/**
 * General component for Management request actions
 */
export default Vue.extend({
  props: {
    requestId: {
      type: Number,
      required: true,
    },
    securityServerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      showDialog: false,
    };
  },
  computed: {
    ...mapStores(managementRequestsStore),
    messageData(): Record<string, unknown> {
      return {
        id: this.requestId,
        serverId: this.securityServerId,
      };
    },
  },
  methods: {
    ...mapActions(notificationsStore, ['showError', 'showSuccess']),
    approve() {
      this.loading = true;
      this.managementRequestsStore
        .approve(this.requestId)
        .then(() => {
          this.showSuccess(
            this.$t(
              'managementRequests.dialog.approve.successMessage',
              this.messageData,
            ),
          );
          this.$emit('approve');
        })
        .catch((error) => {
          this.showError(error);
        })
        .finally(() => {
          this.loading = false;
          this.showDialog = false;
        });
    },
    openDialog() {
      this.showDialog = true;
    },
  },
});
